import { Map, MapBrowserEvent } from 'ol';
import Layer from 'ol/layer/Layer';
import { ReplaySubject, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { MapTooltipComponent } from '../map-tooltip/map-tooltip.component';
import { MapEventHandler, MapEventHandlerOptions } from './map-event-handler';

export interface MapLayerEventHandlerOptions extends MapEventHandlerOptions {
  readonly tooltip: MapTooltipComponent;
}

export type MapLayerEventHandlerCursor = Pick<MapBrowserEvent<any>, 'pixel' | 'dragging'>;

export abstract class MapLayerEventHandler extends MapEventHandler {
  protected readonly tooltip: MapTooltipComponent;
  protected mapViewport?: HTMLElement;

  /**
   * Stream of map move events
   */
  private readonly mapMove$ = new Subject<void>();

  /**
   * The current map cursor info
   */
  private readonly mapCursorEvents$ = new ReplaySubject<MapLayerEventHandlerCursor>(1);

  /**
   * The current feature under the cursor
   */
  protected readonly cursorFeature$ = combineLatest([this.mapCursorEvents$, this.mapMove$.pipe(startWith(null))]).pipe(
    // Don't check while dragging to improve performance
    filter(([{ dragging }]) => !dragging),
    map(([{ pixel }]) => ({
      pixel,
      feature: this.map?.getFeaturesAtPixel(pixel, {
        layerFilter: (layer) => this.layerFilter(layer),
      })?.[0],
    }))
  );

  /**
   * A reference to a bound function that we can use to remove the event listener when disposing of this event handler
   */
  private readonly handleMouseLeaveRef = this.handleMouseLeave.bind(this);

  /**
   * Subscriptions that should be unsubscribed from when this handler is disposed
   */
  protected readonly subscriptions = new Subscription();

  constructor({ setCursor, tooltip }: MapLayerEventHandlerOptions) {
    super({ setCursor });

    this.tooltip = tooltip;
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  public override activate(map: Map): void {
    super.activate(map);

    this.mapViewport = map.getViewport();

    this.mapViewport.addEventListener('mouseleave', this.handleMouseLeaveRef, false);

    this.events.add(map.on('pointermove', ({ pixel, dragging }) => this.mapCursorEvents$.next({ pixel, dragging })));
    this.events.add(map.on('moveend', () => this.mapMove$.next()));
  }

  public override dispose(): void {
    super.dispose();

    this.tooltip.setVisible(false);

    this.mapViewport?.removeEventListener('mouseleave', this.handleMouseLeaveRef, false);

    this.subscriptions.unsubscribe();
  }

  /**
   * Used for filtering layers when finding cursor features
   */
  protected abstract layerFilter(layer: Layer): boolean;

  /**
   * Hides the tooltip when the mouse leaves the map
   */
  private handleMouseLeave(): void {
    this.tooltip?.setVisible(false);
  }
}
