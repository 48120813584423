import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortalHttpClient } from '@grid-ui/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { PollStatusEnum } from '../../../../shared-models/polling';
import * as fromPollingActions from '../actions/polling.actions';
import * as fromPolling from '../reducers';

@Injectable()
export class PollingEffects {
  public startPolling$: Observable<
    fromPollingActions.Poll | fromPollingActions.UpdatePollStatus | fromPollingActions.PollInitialCallError
  > = createEffect(() =>
    this.actions$.pipe(
      ofType<fromPollingActions.StartPolling>(fromPollingActions.PollingActionTypes.StartPolling),
      map((action: fromPollingActions.StartPolling) => action.payload),
      switchMap(({ poll }) =>
        this.http.post(poll.apiConfig, { body: poll.requestBody }).pipe(
          mergeMap((result: any) =>
            of(
              new fromPollingActions.Poll({
                poll: {
                  ...poll,
                  serverPollingSessionId: result.id,
                  status: PollStatusEnum.Active,
                },
              })
            )
          ),
          catchError((error: HttpErrorResponse) => {
            let status = error.status === 400 ? PollStatusEnum.FailedDuplicate : PollStatusEnum.Failed;

            if (poll.requestBody && !poll.requestBody.editions) {
              status = PollStatusEnum.ParameterRequired;
            }

            return of(
              new fromPollingActions.UpdatePollStatus({ id: poll.id, status }),
              new fromPollingActions.PollInitialCallError({ id: poll.id })
            );
          })
        )
      )
    )
  );

  public continuePolling$: Observable<
    | fromPollingActions.UpdatePollStatus
    | fromPollingActions.ContinuePollingSuccess
    | fromPollingActions.PollStatusCallError
    | fromPollingActions.PollStatusCallScoringInProgressError
    | fromPollingActions.PollingComplete
  > = createEffect(() =>
    this.actions$.pipe(
      ofType<fromPollingActions.Poll | fromPollingActions.ContinueOngoingPoll>(
        fromPollingActions.PollingActionTypes.Poll,
        fromPollingActions.PollingActionTypes.ContinueOngoingPoll
      ),
      withLatestFrom(this.store),
      map(([action, state]) => {
        const poll = state.polling.pollList.entities[action.payload.poll.id];
        return {
          poll: action.payload.poll,
          userCancel: poll !== undefined && !!poll.userCancelled,
        };
      }),
      switchMap(({ poll, userCancel }) => {
        if (userCancel) {
          return of(
            new fromPollingActions.UpdatePollStatus({
              id: poll.id,
              status: PollStatusEnum.Cancelled,
            }),
            new fromPollingActions.PollingComplete({ id: poll.id })
          );
        }
        return this.http
          .get<{ status: string }>(poll.apiConfig.pollingEndpoint, {
            pathParams: { id: poll.serverPollingSessionId },
          })
          .pipe(
            mergeMap((response: any) =>
              of(
                new fromPollingActions.ContinuePollingSuccess({
                  response,
                  poll,
                })
              )
            ),
            catchError((error: HttpErrorResponse) =>
              error.status !== 408
                ? of(
                    new fromPollingActions.UpdatePollStatus({
                      id: poll.id,
                      status: PollStatusEnum.Failed,
                    }),
                    new fromPollingActions.PollStatusCallError({ id: poll.id })
                  )
                : of(
                    new fromPollingActions.UpdatePollStatus({
                      id: poll.id,
                      status: PollStatusEnum.TimedOut,
                    }),
                    new fromPollingActions.PollStatusCallError({ id: poll.id })
                  )
            )
          );
      })
    )
  );

  public continuePollingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromPollingActions.ContinuePollingSuccess>(fromPollingActions.PollingActionTypes.ContinuePollingSuccess),
      map((action: fromPollingActions.ContinuePollingSuccess) => action.payload),
      mergeMap(({ poll, response }) => {
        const status = this.getResponsePollStatus(response);
        if (status === 'cancelled') {
          return of(
            new fromPollingActions.UpdatePollStatus({
              id: poll.id,
              status: PollStatusEnum.Cancelled,
            }),
            new fromPollingActions.PollingComplete({ id: poll.id })
          );
        }
        if (status === 'failed') {
          return of(
            new fromPollingActions.UpdatePollStatus({
              id: poll.id,
              status: PollStatusEnum.Failed,
            }),
            new fromPollingActions.PollingComplete({ id: poll.id })
          );
        }
        if (status === 'complete') {
          return of(
            new fromPollingActions.UpdateExtractId({
              id: poll.id,
              extractId: response.link ? response.link.id : undefined,
            }),
            new fromPollingActions.UpdatePollStatus({
              id: poll.id,
              status: PollStatusEnum.Completed,
            }),
            new fromPollingActions.PollingComplete({ id: poll.id })
          );
        }
        if (status === 'score_in_progress') {
          return of(
            new fromPollingActions.UpdatePollStatus({
              id: poll.id,
              status: PollStatusEnum.FailedScoringInProgress,
            }),
            new fromPollingActions.PollingComplete({ id: poll.id })
          );
        } else if (poll.pollCount >= poll.apiConfig.pollLimit) {
          return of(
            new fromPollingActions.UpdatePollStatus({
              id: poll.id,
              status: PollStatusEnum.TimedOut,
            }),
            new fromPollingActions.PollingComplete({ id: poll.id })
          );
        }
        return of(
          new fromPollingActions.Poll({
            poll: {
              ...poll,
              pollCount: poll.pollCount + 1,
            },
          })
        ).pipe(delay(poll.apiConfig.pollingInterval));
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly http: PortalHttpClient,
    private readonly store: Store<fromPolling.State>
  ) {}

  /**
   * The API can return the status as a string (e.g. 'cancelled') or as an object that
   * contains an status code and string.
   */
  private getResponsePollStatus(response: { status: string | { status_string: string } }): string {
    const status = response.status;
    if (status instanceof Object && Object.prototype.hasOwnProperty.call(status, 'status_string')) {
      return status.status_string.toLocaleLowerCase();
    } else if (typeof status === 'string') {
      return status;
    } else {
      console.error('Unrecognized Country Risk Extract poll status: ' + status);
      return '';
    }
  }
}
