import {
  AnalyseScorecardFlatTreeIndicatorGroupNode,
  AnalyseScorecardFlatTreeNode,
  AnalyseScorecardFlatTreeReferenceNode,
} from './analyse-scorecard-flat-tree-node.interface';
import {
  AnalyseScorecardTreeViewGroupNode,
  AnalyseScorecardTreeViewIndicatorGroupNode,
  AnalyseScorecardTreeViewIndicatorNode,
  AnalyseScorecardTreeViewNode,
  AnalyseScorecardTreeViewReferenceNode,
} from './analyse-scorecard-tree-view-node.interface';

/**
 * Typeguard to check whether a scorecard related node is a "tree view" node
 * or a "flat node"
 */
export function isAnalyseScorecardTreeViewNode(
  node: AnalyseScorecardTreeViewNode | AnalyseScorecardFlatTreeNode
): node is AnalyseScorecardTreeViewNode {
  return (node as any).data !== undefined;
}

/**
 * Typeguard to check whether the specified node is a Reference Node (e.g.
 * Risk Index or Site)
 *
 * @param node An Analyse tree node
 */
export function isAnalyseScorecardTreeReferenceNode(
  node: AnalyseScorecardTreeViewNode | AnalyseScorecardFlatTreeNode
): node is AnalyseScorecardTreeViewReferenceNode | AnalyseScorecardFlatTreeReferenceNode {
  const n: AnalyseScorecardFlatTreeNode = isAnalyseScorecardTreeViewNode(node) ? node.data : node;
  return n.type === 'index' || n.type === 'country' || n.type === 'site';
}

/**
 * Typeguard to check whether the specified flat tree node
 * is an expandable node in the "indicator subtree". This can be a reference node (e.g. Risk Index),
 * which is composed of indicators, or it can be an indicator group node.
 *
 * @param node An Analyse tree node
 */
export function isExpandableScorecardFlatIndicatorSubtreeNode(
  node: AnalyseScorecardFlatTreeNode | null | undefined
): node is AnalyseScorecardFlatTreeIndicatorGroupNode | AnalyseScorecardFlatTreeReferenceNode {
  return node !== null && node !== undefined && node.type !== 'group' && node.allowsChildNodes === true;
}

/**
 * Typeguard to check whether the specified node for scorecard display purposes
 * is an expandable node. Note that in the case of reference nodes, this determination
 * is not based purely on node type, as some reference nodes allow indicator subtree children,
 * whereas others do not.
 *
 * @param node An Analyse tree node
 */
export function isExpandableScorecardNode(
  node: AnalyseScorecardTreeViewNode | null | undefined
): node is AnalyseScorecardTreeViewGroupNode | AnalyseScorecardTreeViewIndicatorGroupNode | AnalyseScorecardTreeViewReferenceNode {
  return node !== null && node !== undefined && (node.data.type === 'group' || node.data.allowsChildNodes === true);
}

/**
 * Typeguard to check whether the specified node for scorecard display purposes
 * is a _non_-expandable node. Note that in the case of reference nodes, this determination
 * is not based purely on node type, as some reference nodes allow indicator subtree children,
 * whereas others do not.
 *
 * @param node An Analyse tree node
 */
export function isNonExpandableScorecardNode(
  node: AnalyseScorecardTreeViewNode | null | undefined
): node is AnalyseScorecardTreeViewReferenceNode | AnalyseScorecardTreeViewIndicatorNode {
  return node !== null && node !== undefined && node.data.type !== 'group' && node.data.allowsChildNodes === false;
}
