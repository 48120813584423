import { Injectable } from '@angular/core';
import { WhoAmI } from '@grid-ui/api-models';
import { API_SERVICES_CONFIG, PortalHttpClient } from '@grid-ui/common';

export interface GridAppLoadedEvent {
  event_type: 'grid_app_loaded';
  metadata: {
    account_id?: string;
    user_id?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class GridAnalyticsEventService {
  private resourceConfig = API_SERVICES_CONFIG.feApi.analytics.events;

  constructor(private readonly http: PortalHttpClient) {}

  sendSiteLoadedEvent(userDetails: WhoAmI) {
    const body: GridAppLoadedEvent = {
      event_type: 'grid_app_loaded',
      metadata: {
        account_id: userDetails?.account_uuid,
        user_id: userDetails?.user_uuid,
      },
    };
    return this.http.post(
      {
        path: this.resourceConfig._configuration.path,
      },
      { body }
    );
  }
}
