import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { isDOMRect } from '../models';
import { WINDOW } from '../providers';

const NAV_BAR_HEIGHT = 70;

@Injectable({
  providedIn: 'root',
})
export class WindowUtilitiesService {
  constructor(@Inject(WINDOW) private readonly window: Window | any, @Inject(DOCUMENT) private readonly document: Document) {}

  public disableScroll(): void {
    this.document.body.classList.add('mc-no-scroll');
  }

  public enableScroll(): void {
    this.document.body.classList.remove('mc-no-scroll');
  }

  /**
   * Get a DOM Element using the query selector on the Document Body.
   *
   * *WARNING: This method must only be used in rare edge cases.*
   *
   * Do *not* use this helper, when Angular ViewChild/ViewChildren or
   * ContentChild/ContentChildren can be used.
   */
  public getDOMElementByDocumentQuerySelector<T extends HTMLElement>(cssSelector: string): T | null {
    return this.document.body.querySelector<T>(cssSelector);
  }

  public getFullPath(): string {
    if (this.window instanceof Window) {
      const { pathname, search } = this.window.location;
      return pathname + search;
    }
    return '';
  }

  public getInnerHeight(): number | null {
    if (this.window instanceof Window) {
      return this.window.innerHeight;
    }
    return null;
  }

  public getInnerWidth(): number | null {
    if (this.window instanceof Window) {
      return this.window.innerWidth;
    }
    return null;
  }

  getHostWithProtocol(): string | null {
    if (this.window instanceof Window) {
      const protocol = this.window.location.protocol;
      const host = this.window.location.host;
      return `${protocol}//${host}/`;
    }
    return null;
  }

  public getHref(): string | null {
    if (this.window instanceof Window) {
      const { href } = this.window.location;
      if (href) {
        return href;
      }
    }
    return null;
  }

  public getWindowScrollTop(): number {
    return this.document.documentElement.scrollTop;
  }

  /** Returns true, if the current platform supports use of the Window object */
  public isSupported(): boolean {
    return this.window instanceof Window;
  }

  public openNewTab(url: string): void {
    if (this.window instanceof Window) {
      this.window.open(url, '_blank', 'noopener,noreferrer');
    }
  }

  /** Force a reload of the application, if it is running on a browser platform. Otherwise, does nothing. */
  public reloadApp(): void {
    if (this.window instanceof Window) {
      window.location.reload();
    }
  }

  /** Scrolls to the top of the window, if the app is running on a browser platform. Otherwise, does nothing. */
  public scrollToTop(behavior: ScrollBehavior = 'smooth'): void {
    if (this.window instanceof Window) {
      this.window.scroll({ top: 0, behavior });
    }
  }

  public scrollToElement(element: ElementRef): void {
    if (this.window instanceof Window) {
      const rect = element.nativeElement.getBoundingClientRect();
      // MS browsers don't conform: `getBoundingClientRect()` doesn't return a
      // DOMRect, and so in particular `y` is undefined and `documentElement.scrollTop` can be 0
      let top: number;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if (isDOMRect(rect)) {
        top = rect.y + scrollTop - NAV_BAR_HEIGHT;
      } else {
        top = rect.top + scrollTop - NAV_BAR_HEIGHT;
      }

      this.window.scroll({ top, behavior: 'smooth' });
    }
  }

  public scrollTo(lastScrollTop: number): void {
    if (this.window instanceof Window) {
      this.window.scrollTo(0, lastScrollTop);
    }
  }

  public scrollUpBy(pixelsUp: number): void {
    if (this.window instanceof Window) {
      this.window.scroll({
        top: this.document.documentElement.scrollTop - pixelsUp,
        behavior: 'smooth',
      });
    }
  }

  public setHref(url: string): void {
    if (this.window instanceof Window) {
      this.window.location.href = url;
    }
  }

  public downloadAsset(path: string): void {
    if (this.window instanceof Window) {
      this.window.open(path, '_self');
    }
  }

  public supportsLocalStorage(): boolean {
    return this.window instanceof Window && !!this.window.localStorage;
  }

  private getWindow(): Window | null {
    if (this.window instanceof Window) {
      return this.window;
    } else {
      return null;
    }
  }

  private isHTML5DownloadSupported(): boolean {
    return 'download' in this.document.createElement('a');
  }
}
