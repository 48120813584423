import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DownloadResourceService {
  // TODO: Generify this service and use it on GRID.

  public constructor(
    private http: HttpClient // TODO: Refactor LoggingService into shared lib to be used here // private readonly loggingService: LoggingService
  ) {}

  public download(url: string): Observable<HttpResponse<any>> {
    return this.http
      .get(url, {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(
        catchError((error) =>
          // const message = `PDF Download Failed: ${error && error.message ? error.message : url}`;
          // this.loggingService.log(message, { level: LogLevel.Error, gridErrorType: 'PDF Download' });
          throwError(error as any)
        )
      );
  }

  public downloadAsXlsx(url: string): Observable<Blob> {
    return this.http.get(url, {
      headers: new HttpHeaders({ Accept: 'application/vnd.ms-excel' }),
      responseType: 'blob',
    });
  }

  public downloadAsCsv(url: string): Observable<HttpResponse<any>> {
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  public getFilenameByContendDisposition(contentDisposition: string): string {
    return contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
  }
}
