import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { globalModuleAnnouncementSelectors } from './global-module-announcement.selectors';
import { GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS } from './global-module-announcement.actions';
import { AnnouncementVM, GlobalModuleAnnouncementState } from './global-module-announcement.reducer';

@Injectable()
export class GlobalModuleAnnouncementFacade {
  undissmissedAnnouncements$: Observable<AnnouncementVM[]> = this.store.select(
    globalModuleAnnouncementSelectors.selectUndissmissedAnnouncements
  );

  constructor(private store: Store<GlobalModuleAnnouncementState>) {}

  loadAnnouncements(): void {
    this.store.dispatch(GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS.load());
  }

  dismissAnnoucments(payload: string[]) {
    this.store.dispatch(GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS.dismiss({ payload }));
  }
}
