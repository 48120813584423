import { ApiServicesConfigModel, format } from '@grid-ui/common';

/**
 * Function used to build the PDF download URLs for
 * CommodityRisk and CountryRisk modules and their Insights and Profiles submodules.
 * @param module
 * @param subModule
 * @param apiServiceConfig API service conflict object
 */
export function downloadPdfUrlBuilder(
  module: Extract<keyof ApiServicesConfigModel['v3'], 'countryRisk' | 'commodityRisk'>,
  subModule: Extract<
    keyof ApiServicesConfigModel['v3']['countryRisk']['content'] | ApiServicesConfigModel['v3']['commodityRisk']['content'],
    'insights' | 'profiles'
  >,
  apiServiceConfig: ApiServicesConfigModel
): (id: string | number | null) => string {
  return (id: number | string | null) => {
    if (module === 'countryRisk' && subModule === 'profiles') {
      return format(apiServiceConfig.v3.countryRisk.content.profiles.detail._configuration.path, { id }) + '?type=pdf';
    }

    if (subModule === 'insights') {
      return format(apiServiceConfig.v3[module].content.insights.detail._configuration.path, { id }) + '?type=pdf';
    }

    throw new Error(`Invalid subModule "${subModule}" for module "${module}".`);
  };
}
