import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataWizardExtractNotificationService } from '../../../extract-management';
import { ServiceOfferingCard } from '../../../home/models';
import { SvgService } from '../../services';

@Component({
  selector: 'mc-app-side-area-links',
  templateUrl: './app-side-area-links.component.html',
  styleUrls: ['./app-side-area-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSideAreaLinksComponent implements OnInit {
  @Input() navItems: ServiceOfferingCard[] = [];
  @Input() currentModule: string | null = null;

  public extractActive$: Observable<boolean>;
  public extractComplete$: Observable<boolean>;

  constructor(
    private readonly extractNotificationService: DataWizardExtractNotificationService,
    private readonly svgService: SvgService,
    private readonly router: Router
  ) {}

  public ngOnInit(): void {
    this.extractActive$ = this.extractNotificationService.extractActive();
    this.extractComplete$ = this.extractNotificationService.extractCompleteVisible();
  }

  public trackByIndex(item: ServiceOfferingCard, index: number): number {
    return index;
  }

  public getSvg(svgId: string): string {
    return this.svgService.getNavigationIcon(svgId);
  }

  public navigate(navItem: ServiceOfferingCard) {
    if (navItem.open_in_window) {
      window.open(navItem.link);
    } else {
      this.router.navigate([navItem.link]);
    }
  }
}
