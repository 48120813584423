import { trigger, state, style, transition, animate, group, query, stagger, keyframes } from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible',
        padding: '20px',
      })
    ),
    state(
      'out',
      style({
        'max-height': '0px',
        opacity: '0',
        visibility: 'hidden',
        padding: '0px',
      })
    ),
    transition('in => out', [group([])]),
    transition('out => in', [
      group([
        animate(
          '0ms ease-in-out',
          style({
            visibility: 'visible',
            padding: '20px',
          })
        ),
        animate(
          '200ms ease-in-out',
          style({
            'max-height': '500px',
          })
        ),
        animate(
          '400ms ease-in-out',
          style({
            opacity: '1',
          })
        ),
      ]),
    ]),
  ]),
];
