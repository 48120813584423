import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SlideInOutAnimation } from '../slide-in-out';

@Component({
  selector: 'grid-ui-accordion-panel',
  templateUrl: './accordion-panel.component.html',
  styleUrls: ['./accordion-panel.component.scss'],
  animations: [SlideInOutAnimation],
})
export class AccordionPanelComponent {
  @Input() public animationState = 'out';
  /**
   * If the panel is opened or closed
   */
  @Input() public isActive = false;
  @Input() public isDisabled = false;
  @Input()
  private set searchedTerm(searchedTerm: string) {
    this.searchedTermValue = searchedTerm;
  }
  private get searchedTerm(): string {
    return this.searchedTermValue;
  }
  /**
   * Text to display in the group title bar
   */
  @Input() public title = '';

  /**
   * Emitted when user clicks on group titlebar
   * @type {EventEmitter<any>}
   */
  @Output() public toggle: EventEmitter<any> = new EventEmitter<any>();

  private searchedTermValue = '';
}
