import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isObservable } from 'rxjs';
import { ResolvedReleaseNote } from '../../feature-release/feature-release-detail';
import { RouteBreadcrumb, RouteConfigBreadcrumbData } from '../../shared-models';

/**
 * A service support the creation of a breadcrumbs trail for
 * App Top Area navigation bar. The service allows parsing the
 * Activated Routes to extract breadcrumbs which have been either
 * statically defined or bound to resolvers in the corresponding
 * route definitions.
 */
@Injectable()
export class TopBreadcrumbTrailService {
  /**
   * Create a breadcrumb trail from the current router state/activated routes by extracting
   * static breadcrumbs or resolved breadcrumb data along the activated routes.
   *
   * Only "primary" router outlets are considered. Secondary router outlets and their corresponding
   * url path parts are ignored as part of parsing the router state tree.
   *
   * @param root Activated Route snapshot of the root route as accessed through
   * router.routerState.snapshot.root
   * @param state Router state snapshot, as accessed through this.router.routerState.snapshot
   * @param breadcrumbsSeed Optional array of breadcrumbs, which will be pre-pended to each
   * breadcrumb trail. These represent the "Home" navigation breadcrumbs, which are usually not
   * on the current route path
   */
  public createBreadcrumbsTrail(
    root: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    breadcrumbsSeed: RouteBreadcrumb[] = []
  ): RouteBreadcrumb[] {
    let breadcrumbs = [...breadcrumbsSeed];

    const parseRoute = (node: ActivatedRouteSnapshot): void => {
      if (node.data['breadcrumb']) {
        const breadcrumbData: RouteConfigBreadcrumbData = node.data['breadcrumb'];
        if (typeof breadcrumbData === 'string') {
          breadcrumbs.push({
            label: node.data['breadcrumb'],
            route: this.getRouteUrl(node),
          });
        } else {
          const partialTrail: RouteBreadcrumb[] = node.data['breadcrumb'];
          breadcrumbs = breadcrumbs.concat(partialTrail);
        }
      } else if (isObservable(node.data['apiData'])) {
        node.data['apiData'].subscribe((note: ResolvedReleaseNote) => {
          breadcrumbs.push({
            label: note.breadcrumb,
            route: this.getRouteUrl(node),
          });
        });
      }
      // IMPORTANT: Only consider path along "primary" outlets
      // in recursion
      if (node.children.length > 0) {
        const primary = node.children.find((child) => child.outlet === 'primary');
        if (primary !== undefined) {
          parseRoute(primary);
        }
      }
    };

    parseRoute(root);

    return breadcrumbs;
  }

  private getRouteUrl(node: ActivatedRouteSnapshot): string {
    return (
      '/' +
      node.pathFromRoot
        .reduce((urls, url) => [...urls, ...url.url], [])
        .map((url) => url.path)
        .join('/')
    );
  }
}
