import { Injectable } from '@angular/core';
import * as R from 'ramda';

import {
  API_SERVICES_CONFIG,
  CountryRiskConfigurationDetailLocationTreeResourcesConfig,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  PaginatedResourceConfig,
  PaginationService,
  PathParams,
  PortalHttpClient,
} from '@grid-ui/common';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  AnalyseDataTableAllHistoricalScoresPage,
  AnalyseDataTablePage,
  AnalyseRedirectedResponse,
  ApiCountryRiskViewsLocationScoreTableParameters,
  CountryRiskViewsLocationScoreTableParameters,
} from '../../../shared-models';
import { CountryRiskViewsBaseTreeService } from '../../country-risk-views-base-tree';
import {
  ApiAnalyseDataTableAllHistoricalScoresPage,
  ApiAnalyseDataTablePage,
  TreeBulkAddLeavesParameter,
  TreeBulkLeafAdditionIndividualBody,
  TreeBulkLeafAdditionSelectAllBody,
} from '../../models';
import {
  mapAllDataTableHistoricalFlatNodeFromApiToApp,
  mapAllDataTableNonHistoricalFlatNodeFromApiToApp,
  mapDataTableNonHistoricalColumnsFromApiToApp,
} from '../utils';

@Injectable({
  providedIn: 'root',
})
/**
 * Service for accessing the Country Risk Configurations (Views) Location Trees API.
 * Supports getting the Locations Tree associated with a given configuration as well
 * as API transactions related to Locations Tree editing
 */
export class CountryRiskViewsLocationsTreeService extends CountryRiskViewsBaseTreeService {
  private locationTreeResourceConfig: CountryRiskConfigurationDetailLocationTreeResourcesConfig;

  constructor(retryableHttpClient: PortalHttpClient, paginationService: PaginationService) {
    super(retryableHttpClient, paginationService);
    this.initApiConfigs(
      API_SERVICES_CONFIG.v3.countryRisk.configurations.configuration.location,
      API_SERVICES_CONFIG.v3.countryRisk.configurations.configuration.locationTree
    );
    this.locationTreeResourceConfig = API_SERVICES_CONFIG.v3.countryRisk.configurations.configuration.locationTree;
  }

  public bulkAddLeaves(
    viewId: number,
    params: TreeBulkAddLeavesParameter,
    body: TreeBulkLeafAdditionIndividualBody | TreeBulkLeafAdditionSelectAllBody
  ): Observable<AnalyseRedirectedResponse> {
    return super.bulkAddLeaves(viewId, params, body);
  }

  /**
   * Get a page of data for the Data Table in "All Historical Scores" mode.
   *
   * @param parameters Query Parameters
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getAllHistoricalScores(
    parameters: CountryRiskViewsLocationScoreTableParameters,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<AnalyseDataTableAllHistoricalScoresPage> {
    const resourceConfiguration = this.getResourceConfiguration('scoreHistory');
    const pageSize = resourceConfiguration.maxPageSize || resourceConfiguration.defaultPageSize;
    const apiParams: ApiCountryRiskViewsLocationScoreTableParameters = {
      ...parameters,
      page: 1,
      page_size: pageSize,
    };

    return this.getSinglePageAllHistoricalScores(apiParams, options).pipe(
      switchMap((startPage) => {
        const page_end = Math.ceil(startPage.total / pageSize);
        const pageObs: Observable<AnalyseDataTableAllHistoricalScoresPage>[] = [of(startPage)];
        R.range(2, page_end + 1).forEach((i) => {
          const currentApiParams: ApiCountryRiskViewsLocationScoreTableParameters = {
            ...parameters,
            page: i,
            page_size: pageSize,
          };
          pageObs.push(this.getSinglePageAllHistoricalScores(currentApiParams, options));
        });
        return this.forkJoinMultipleHistoricalScoresPages(pageObs);
      })
    );
  }

  /** Provide the right resource configuration. Keep public for testing. */
  public getResourceConfiguration(resource: 'scoreHistory' | 'groupNodeScoreTable' | 'rootScoreTable'): PaginatedResourceConfig {
    switch (resource) {
      case 'scoreHistory':
        return this.locationTreeResourceConfig.rootNodeScoredTree.scoreHistory._configuration;
      case 'rootScoreTable':
        return this.locationTreeResourceConfig.rootNodeScoredTree.scoreTable._configuration;
      case 'groupNodeScoreTable':
        return this.locationTreeResourceConfig.groupNodeScoredTree.scoreTable._configuration;
    }
  }

  /**
   * Get a page of data for the Data Table in "regular" mode, i.e. _not_ "All Historical Scores" mode.
   *
   * @param parameters Query Parameters
   * @param options An optional argument with custom options for the underlying Http GET request
   */
  public getScoredTreeTable(
    parameters: CountryRiskViewsLocationScoreTableParameters,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<AnalyseDataTablePage> {
    const resourceConfiguration: PaginatedResourceConfig =
      parameters.pathParameters.group !== undefined
        ? this.getResourceConfiguration('groupNodeScoreTable')
        : this.getResourceConfiguration('rootScoreTable');
    const pageSize = resourceConfiguration.maxPageSize || resourceConfiguration.defaultPageSize;
    const apiParams: ApiCountryRiskViewsLocationScoreTableParameters = {
      queryParameters: parameters.queryParameters,
      pathParameters: parameters.pathParameters,
      page: 1,
      page_size: pageSize,
    };

    return this.getSinglePageScoredTreeTable(resourceConfiguration, apiParams, options).pipe(
      switchMap((startPage) => {
        const page_end = Math.ceil(startPage.total / pageSize);
        const pageObs: Observable<AnalyseDataTablePage>[] = [of(startPage)];
        R.range(2, page_end + 1).forEach((i) => {
          const currentApiParams: ApiCountryRiskViewsLocationScoreTableParameters = {
            ...apiParams,
            page: i,
            page_size: pageSize,
          };
          pageObs.push(this.getSinglePageScoredTreeTable(resourceConfiguration, currentApiParams, options));
        });
        return this.forkJoinMultipleScoredTreeTablePages(pageObs);
      })
    );
  }

  private forkJoinMultipleHistoricalScoresPages(
    pageObservables: Observable<AnalyseDataTableAllHistoricalScoresPage>[]
  ): Observable<AnalyseDataTableAllHistoricalScoresPage> {
    return forkJoin(pageObservables).pipe(
      map((pages) =>
        pages.reduce((previous, current) => {
          const accumulated: AnalyseDataTableAllHistoricalScoresPage = {
            total: current.total,
            columns: current.columns,
            historicalScoresMode: current.historicalScoresMode,
            scored: current.scored,
            scoring_progress: current.scoring_progress,
            results: previous.results.concat(current.results),
          };
          return accumulated;
        })
      )
    );
  }

  private forkJoinMultipleScoredTreeTablePages(pageObservables: Observable<AnalyseDataTablePage>[]): Observable<AnalyseDataTablePage> {
    return forkJoin(pageObservables).pipe(
      map((pages) =>
        pages.reduce((previous, current) => {
          const accumulated: AnalyseDataTablePage = {
            total: current.total,
            columns: current.columns,
            historicalScoresMode: current.historicalScoresMode,
            editions: current.editions,
            scored: current.scored,
            scoring_progress: current.scoring_progress,
            results: previous.results.concat(current.results),
          };
          return accumulated;
        })
      )
    );
  }

  private getSinglePageAllHistoricalScores(
    parameters: ApiCountryRiskViewsLocationScoreTableParameters,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<AnalyseDataTableAllHistoricalScoresPage> {
    const queryParams = {
      ...parameters.queryParameters,
      page: parameters.page,
      page_size: parameters.page_size,
    };
    const pathParams: PathParams = { ...parameters.pathParameters };
    return super
      .getHttpClient()
      .get<ApiAnalyseDataTableAllHistoricalScoresPage>(this.getResourceConfiguration('scoreHistory'), {
        ...options,
        queryParams,
        pathParams,
      })
      .pipe(
        map((response) => {
          const responsePage: AnalyseDataTableAllHistoricalScoresPage = {
            historicalScoresMode: 'all',
            scored: response.scored,
            // TODO: P2-3111 Remove 1 fallback, once API is always providing scoring_progress property
            scoring_progress: typeof response.scoring_progress === 'number' ? response.scoring_progress : 1,
            total: response.total,
            results: response.results.map((apiNode) => mapAllDataTableHistoricalFlatNodeFromApiToApp(apiNode)),
            columns: response.columns,
          };
          return responsePage;
        })
      );
  }

  private getSinglePageScoredTreeTable(
    resourceConfiguration: PaginatedResourceConfig,
    parameters: ApiCountryRiskViewsLocationScoreTableParameters,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<AnalyseDataTablePage> {
    const queryParams = {
      ...parameters.queryParameters,
      page: parameters.page,
      page_size: parameters.page_size,
    };
    return super
      .getHttpClient()
      .getPaginated<ApiAnalyseDataTablePage>(resourceConfiguration, {
        ...options,
        queryParams,
        pathParams: parameters.pathParameters,
      })
      .pipe(
        map((response) => {
          const responsePage: AnalyseDataTablePage = {
            historicalScoresMode: 'none',
            total: response.response.total,
            editions: response.response.editions,
            scored: response.response.scored,
            // TODO: P2-3111 Remove 1 fallback, once API is always providing scoring_progress property
            scoring_progress: typeof response.response.scoring_progress === 'number' ? response.response.scoring_progress : 1,
            results: response.response.results.map((apiNode) => mapAllDataTableNonHistoricalFlatNodeFromApiToApp(apiNode)),
            columns: mapDataTableNonHistoricalColumnsFromApiToApp(response.response.columns),
          };
          return responsePage;
        })
      );
  }
}
