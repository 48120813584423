import { HttpErrorResponse } from '@angular/common/http';
import { WhoAmI } from '@grid-ui/api-models';
import { LoadingRequestStatus, SavingRequestStatus } from '@grid-ui/common';
import { createReducer, on } from '@ngrx/store';
import { WhoAmIUserPersonalInformation } from '../models';
import {
  getWhoAmISuccess,
  whoAmILoadUser,
  whoAmILoadUserFailed,
  whoAmILoadUserSucceeded,
  whoAmIResetSaveUser,
  whoAmIResetUser,
  whoAmISaveUser,
  whoAmISaveUserFailed,
  whoAmISaveUserSucceeded,
} from './who-am-i.actions';

export interface LoadingState {
  status: LoadingRequestStatus;
  error: HttpErrorResponse | null;
}

export interface SavingState {
  status: SavingRequestStatus;
  error: HttpErrorResponse | null;
}

export interface WhoAmIState {
  whoAmI?: WhoAmI;
  user: WhoAmIUserPersonalInformation | null;
  userLoadingStatus: LoadingState;
  userSavingStatus: SavingState;
}

export const whoAmIInitialState: WhoAmIState = {
  user: null,
  userLoadingStatus: {
    status: LoadingRequestStatus.initial,
    error: null,
  },
  userSavingStatus: {
    status: SavingRequestStatus.initial,
    error: null,
  },
};

export const whoAmIReducer = createReducer(
  whoAmIInitialState,

  on(getWhoAmISuccess, (state, { whoAmI }) => ({ ...state, whoAmI })),

  on(whoAmILoadUser, (state) => ({
    ...state,
    userLoadingStatus: { status: LoadingRequestStatus.loading, error: null },
  })),

  on(whoAmILoadUserSucceeded, (state, action) => ({
    ...state,
    user: action.payload,
    userLoadingStatus: { status: LoadingRequestStatus.loaded, error: null },
  })),

  on(whoAmILoadUserFailed, (state, action) => ({
    ...state,
    user: null,
    userLoadingStatus: {
      status: LoadingRequestStatus.error,
      error: action.payload,
    },
  })),

  on(whoAmISaveUser, (state) => ({
    ...state,
    userSavingStatus: { status: SavingRequestStatus.saving, error: null },
  })),

  on(whoAmISaveUserSucceeded, (state, action) => ({
    ...state,
    user: action.payload,
    userLoadingStatus: { status: LoadingRequestStatus.loaded, error: null },
    userSavingStatus: { status: SavingRequestStatus.saved, error: null },
  })),

  on(whoAmISaveUserFailed, (state, action) => ({
    ...state,
    userSavingStatus: {
      status: SavingRequestStatus.error,
      error: action.payload,
    },
  })),

  on(whoAmIResetUser, () => ({
    ...whoAmIInitialState,
  })),

  on(whoAmIResetSaveUser, (state) => ({
    ...state,
    userSavingStatus: { status: SavingRequestStatus.initial, error: null },
  }))
);
