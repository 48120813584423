import { GridSortModel, GridSortCriterion } from './models';

export function mapGridSortModelToApiSortParam(
  sortModel: GridSortModel,
  colIdMap?: Record<string, string>,
  multi = false
): string | undefined {
  const mapCriterion = (c: GridSortCriterion) => {
    const colId = colIdMap ? colIdMap[c.colId] || c.colId : c.colId;
    return c.sort === 'asc' ? colId : `-${colId}`;
  };

  if (sortModel.length === 0) {
    return undefined;
  } else if (multi) {
    return sortModel.map(mapCriterion).join(',');
  } else {
    return mapCriterion(sortModel[0]);
  }
}

export function mapGridSortModelToApiSortParamV4(
  sortModel: GridSortModel,
  colIdMap: Record<string, string>,
  multi = false
): string | undefined {
  const mappedModel = sortModel.map((s: GridSortCriterion) => (s.colId in colIdMap ? s : { ...s, colId: `attributes__${s.colId}` }));

  return mapGridSortModelToApiSortParam(mappedModel, colIdMap, multi);
}
