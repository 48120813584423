import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as R from 'ramda';
import { API_SERVICES_CONFIG, GLOBAL_DEFAULT_RETRY_ATTEMPTS } from '../api-services.config';
import { ResponseCodeRetryConfiguration, RetryConfiguration, RetryConfigurationMethod } from '../models';

const WILDCARD_STRINGS: { [key: number]: string } = {
  1: 'x',
  2: 'xx',
  3: 'xxx',
};

@Injectable({
  providedIn: 'root',
})
export class RetryAttemptsMapperService {
  private globalRetryConfig: RetryConfiguration;

  public constructor() {
    this.globalRetryConfig = API_SERVICES_CONFIG.globalRetryConfig;
  }

  public getRetryAttempts(
    method: RetryConfigurationMethod,
    errorResponse: HttpErrorResponse,
    resourceRetryConfig?: RetryConfiguration,
    customRetries?: number
  ): number {
    if (customRetries !== undefined) {
      return Math.max(0, Math.floor(customRetries));
    }
    const responseCode = errorResponse.status;

    let retryAttempts = this.findRetryAttemptsForRetryConfig(resourceRetryConfig, responseCode, method);
    if (retryAttempts === null) {
      retryAttempts = this.findRetryAttemptsForRetryConfig(this.globalRetryConfig, responseCode, method);
    }

    return retryAttempts !== null ? retryAttempts : GLOBAL_DEFAULT_RETRY_ATTEMPTS;
  }

  private findRetryAttemptsForRetryConfig(
    resourceRetryConfig: RetryConfiguration | undefined,
    responseCode: number,
    method: RetryConfigurationMethod
  ): number | null {
    let retryAttempts: number | null = this.findRetryAttemptsForRequestMethod(resourceRetryConfig, responseCode, method);
    if (retryAttempts === null) {
      retryAttempts = this.findDefaultRetryAttempts(resourceRetryConfig, responseCode);
    }
    return retryAttempts;
  }

  private findDefaultRetryAttempts(resourceRetryConfig: RetryConfiguration | undefined, responseCode: number): number | null {
    if (resourceRetryConfig === undefined) {
      return null;
    }
    let retryAttempts: number | null = null;
    if (resourceRetryConfig['default'] !== undefined) {
      const responseCodeRetryConfigs = resourceRetryConfig['default'];
      if (responseCodeRetryConfigs !== undefined && typeof responseCodeRetryConfigs !== 'number') {
        retryAttempts = this.getRetryAttemptForResponseCode(responseCodeRetryConfigs, responseCode);
      } else if (responseCodeRetryConfigs !== undefined) {
        retryAttempts = responseCodeRetryConfigs as number;
      }
    }
    return retryAttempts;
  }

  private findRetryAttemptsForRequestMethod(
    resourceRetryConfig: RetryConfiguration | undefined,
    responseCode: number,
    method: RetryConfigurationMethod
  ): number | null {
    if (resourceRetryConfig === undefined) {
      return null;
    }
    const responseCodeRetryConfigs = resourceRetryConfig[method];
    if (responseCodeRetryConfigs !== undefined && typeof responseCodeRetryConfigs !== 'number') {
      return this.getRetryAttemptForResponseCode(responseCodeRetryConfigs, responseCode);
    }
    return null;
  }

  private getRetryAttemptForResponseCode(responseCodeRetryConfigs: ResponseCodeRetryConfiguration[], responseCode: number): number | null {
    if (!responseCode || responseCode < 300) {
      return null;
    }
    const responseCodeString = responseCode.toString();
    let retryAttempts: number | null = null;
    let i = 0;
    while (retryAttempts === null && i < 4) {
      const wildcardResponseCode = this.getWildcardedResponseCode(responseCodeString, i);
      retryAttempts = this.matchResponseCodeConfig(responseCodeRetryConfigs, wildcardResponseCode);
      i++;
    }
    return retryAttempts;
  }

  private getWildcardedResponseCode(responseCodeString: string, wildcardCount: number) {
    if (wildcardCount === 0) {
      return responseCodeString;
    } else {
      return responseCodeString.slice(0, 3 - wildcardCount) + WILDCARD_STRINGS[wildcardCount];
    }
  }

  private matchResponseCodeConfig(responseCodeRetryConfigs: ResponseCodeRetryConfiguration[], responseCode: string): number | null {
    const exactMatch = R.find(R.propEq('responseCode', responseCode), responseCodeRetryConfigs) as ResponseCodeRetryConfiguration;
    return exactMatch ? exactMatch.retryAttempts : null;
  }
}
