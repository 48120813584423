import { GridApi } from '@ag-grid-community/core';
import { CustomRowDataChangedEvent, ErrorComponentData, HttpErrorHandlerService } from '../../..';

export abstract class GridDataSourceComponent {
  public error: ErrorComponentData | null = null;
  public gridApi?: GridApi;
  private _rowsCount?: number | null;
  private _loaded?: boolean;

  public constructor(public readonly httpErrorHandlerService: HttpErrorHandlerService) {}

  public get rowsCount(): number {
    return this._rowsCount || 0;
  }

  public get loaded(): boolean {
    return !!this._loaded;
  }

  public handleRowDataChange(e: CustomRowDataChangedEvent) {
    switch (e?.customType) {
      case 'rowDataLoadingFailed':
        this._loaded = false;
        this.error = this.httpErrorHandlerService.mapHttpErrorResponseToUIComponent(e.error, 'Try Again', 'Reload App');
        break;
      case 'rowDataLoadingSuccess':
        this._loaded = true;
        this._rowsCount = e.total;
        break;
      case 'filteredTotalUpdated':
        this._rowsCount = e.filteredTotal;
        break;
    }

    if (this.rowsCount === 0 && this.loaded) {
      this.gridApi?.showNoRowsOverlay();
    } else if (this.rowsCount > 0) {
      this.gridApi?.hideOverlay();
    }
  }
}
