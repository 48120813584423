<div class="card nav mc-home-service-offering-card" id="glp-card-{{ card.elementId }}">
  <div class="container d-flex flex-column" [class.justify-content-between]="card.menu && card.menu.length > 1" (click)="containerClick()">
    <ng-container *ngIf="!showMenu">
      <div class="mc-home-service-offering-card__header">
        <grid-ui-icon
          id="country-risk-icon"
          *ngIf="imageHref === 'shape-country-risk'"
          class="mc-home-service-offering-card__header-icon"
          name="country-risk"
        ></grid-ui-icon>
        <grid-ui-icon
          id="learn-icon"
          *ngIf="imageHref === 'shape-video'"
          class="mc-home-service-offering-card__header-icon"
          name="video"
        ></grid-ui-icon>
        <grid-ui-icon
          id="sovereign-esg-ratings-icon"
          *ngIf="imageHref === 'shape-sovereign-esg-ratings'"
          class="mc-home-service-offering-card__header-icon"
          name="sovereign"
        ></grid-ui-icon>
        <grid-ui-icon
          id="commodity-risk-icon"
          *ngIf="imageHref === 'shape-commodity-risk'"
          class="mc-home-service-offering-card__header-icon"
          name="commodity-risk"
        ></grid-ui-icon>
        <grid-ui-icon
          id="industry-risk-icon"
          *ngIf="imageHref === 'shape-industry-risk'"
          class="mc-home-service-offering-card__header-icon"
          name="industry-chart"
        ></grid-ui-icon>
        <grid-ui-icon
          id="corporate-exposure-icon"
          *ngIf="imageHref === 'shape-corporate-exposure'"
          class="mc-home-service-offering-card__header-icon"
          name="corporate-exposure"
        ></grid-ui-icon>
        <grid-ui-icon
          id="data-wizard-icon"
          *ngIf="imageHref === 'shape-data-wizard'"
          class="mc-home-service-offering-card__header-icon"
          name="data-wizard"
        ></grid-ui-icon>
        <grid-ui-icon
          id="document-library-icon"
          *ngIf="imageHref === 'shape-document-library'"
          class="mc-home-service-offering-card__header-icon"
          name="document-library"
        ></grid-ui-icon>
        <grid-ui-icon
          id="email-alerts-icon"
          *ngIf="imageHref === 'shape-email-alerts'"
          class="mc-home-service-offering-card__header-icon"
          name="email"
        ></grid-ui-icon>
        <grid-ui-icon
          id="webinars-icon"
          *ngIf="imageHref === 'shape-news-and-events'"
          class="mc-home-service-offering-card__header-icon"
          name="calendar"
        ></grid-ui-icon>
      </div>
      <div class="details">
        <h2 class="m-0 details-title">{{ card.label }}</h2>
        <p class="lh-24 details-description">{{ card.description }}</p>
      </div>
    </ng-container>
    <div *ngIf="showMenu" class="details" data-hook="quick-jump-menu">
      <h2 class="p-3 details-title">{{ card.label }}</h2>
      <a *ngFor="let item of card.menu" (click)="linkClick(item.link)" class="quick-link" data-hook="quick-jump-link">
        {{ item.label }}
      </a>
    </div>
    <div *ngIf="card.menu && card.menu.length > 1" class="menu-button-container text-center">
      <button class="btn mc-btn-secondary" (click)="toggleMenu($event)" data-hook="quick-jump-button">
        Quick Jump
        <span class="jump-button-icon ml-2 icon extra-small">
          <grid-ui-icon class="icon" *ngIf="!showMenu" name="chevron-circle-up"></grid-ui-icon>
          <grid-ui-icon class="icon" *ngIf="showMenu" name="chevron-circle-down"></grid-ui-icon>
        </span>
      </button>
    </div>
  </div>
</div>
