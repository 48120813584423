<div class="accordion-panel">
  <div
    class="accordion-panel__title"
    [ngClass]="{
      'accordion-panel__title--active': isActive,
      'accordion-panel__title--inactive': !isActive
    }"
    (click)="toggle.emit()"
    (keyup.enter)="toggle.emit()"
    tabindex="0"
  >
    <grid-ui-icon class="accordion-panel__icon" [class.expanded]="isActive" name="chevron-right" [useInlineSVG]="true"></grid-ui-icon>
    <span>{{ title }}</span>
  </div>
  <div class="body" [@slideInOut]="animationState">
    <ng-content></ng-content>
  </div>
  <div></div>
</div>
