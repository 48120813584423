import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WhoAmIState } from './who-am-i.reducer';

export const selectWhoAmIState = createFeatureSelector<WhoAmIState>('whoAmI');

export const selectWhoAmI = createSelector(selectWhoAmIState, (state) => state.whoAmI);

export const selectWhoAmIUserName = createSelector(selectWhoAmI, (whoAmI) => {
  if (whoAmI) {
    return {
      first_name: whoAmI.first_name,
      last_name: whoAmI.last_name,
    };
  }

  return null;
});

export const selectWhoAmIPersonalInformation = createSelector(selectWhoAmIState, (state) => state.user);

export const selectWhoAmIProfile = createSelector(selectWhoAmIPersonalInformation, (user) =>
  user !== null && user.profile !== null ? user.profile : null
);

export const selectWhoAmIUserSavingStatus = createSelector(selectWhoAmIState, (state) => state.userSavingStatus);

export const selectLoggedIn = createSelector(selectWhoAmIPersonalInformation, (user) => !!user);
