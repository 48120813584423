import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { API_SERVICES_CONFIG } from '../../../api';
import { HttpErrorHandlerService } from '../../../api/services';
import { CountryRiskIndexNarrative, INITIAL_GENERAL_LOADING_CONTENT, LoadingRequestStatus } from '../../../shared-models';
import {
  WrittenContentLinkNavigateToPortalRouteAction,
  WrittenContentLinkRiskIndexInformationAction,
} from '../../listeners/written-content-anchor-click-listener';
import { IndexInfoPopoverComponent } from '../index-info-popover';

@Injectable()
export class IndexInfoPopoverService {
  private downloadPathTemplate: string;

  public constructor(private readonly ngbModalService: NgbModal, private readonly httpErrorHandlerService: HttpErrorHandlerService) {
    this.downloadPathTemplate = API_SERVICES_CONFIG.v3.countryRisk.indices.index.narrative._configuration.path;
  }

  /**
   * A convenience method to show the Risk Index Methodolgy Modal, based
   * on the specified index narrative observable.
   *
   * Should the index narrative observable throw an HttpRequestError, the modal
   * will display the error component.
   *
   * The method returns a Promise based on the Modal dismissal. The promise will, resolve to
   *
   * - null, if the user closes the modal,
   * - HttpErrorResponse, if the user clicked the "Try Again" button in a modal which failed to
   * load the narrative
   * - WrittenContentLinkNavigateToPortalRouteAction, if the user clicked on a written content link
   * in the index narrative requesting the navigation to another app route in the same tab
   * - WrittenContentLinkRiskIndexInformationAction, if the user clicked on a written content link
   * in the index narrative requesting information about a linked risk index
   *
   * @param indexNarrativeObs An observable of the country risk index narrative
   */
  public showIndexInfoPopover(
    indexNarrativeObs: Observable<CountryRiskIndexNarrative>
  ): Promise<null | HttpErrorResponse | WrittenContentLinkNavigateToPortalRouteAction | WrittenContentLinkRiskIndexInformationAction> {
    const modalRef = this.ngbModalService.open(IndexInfoPopoverComponent, {
      size: 'lg',
      backdrop: true,
      windowClass: 'grid-ui-popover-modal-custom-class',
    });

    const indexInfoModal: IndexInfoPopoverComponent = modalRef.componentInstance;

    indexInfoModal.indexNarrative = INITIAL_GENERAL_LOADING_CONTENT;
    indexInfoModal.downloadPathTemplate = this.downloadPathTemplate;

    indexNarrativeObs.pipe(take(1)).subscribe(
      (data) => {
        indexInfoModal.indexNarrative = {
          content: data,
          error: null,
          loadingStatus: LoadingRequestStatus.loaded,
        };
        indexInfoModal.closeReason = null;
      },
      (err: HttpErrorResponse) => {
        indexInfoModal.indexNarrative = {
          content: null,
          error: this.httpErrorHandlerService.mapHttpErrorResponseToUIComponent(err, 'Try Again', null, 'full'),
          loadingStatus: LoadingRequestStatus.error,
        };
        indexInfoModal.closeReason = err;
      }
    );

    return modalRef.result;
  }
}
