import { Cursor } from '@grid-ui/common';
import Feature, { FeatureLike } from 'ol/Feature';
import Layer from 'ol/layer/Layer';
import { getFeatureExtent, isCluster } from './helpers';
import { DEFAULT_MAP_CURSOR } from './map-event-handler';
import { MapLayerEventHandler, MapLayerEventHandlerOptions } from './map-layer-event-handler';

export interface FeatureClickAndZoomEventHandlerOptions extends MapLayerEventHandlerOptions {
  /**
   * The class name of the OpenLayers layer.
   * Filtering is a performance optimisation.
   */
  readonly layerClassName: string;
}

/**
 * Handles feature click and zoom.
 *
 * Very useful for site score cluster markers
 * where we want to click and zoom into their
 * extent.
 *
 * @warning - This event handler has hard-coded
 * references to expect a 'siteName' or 'siteId'
 * which is not ideal. Todo - find a way to pass
 * this logic in as a function.
 */
export class FeatureClickAndZoomEventHandler extends MapLayerEventHandler {
  private readonly layerClassName: string;

  constructor({ setCursor, tooltip, layerClassName }: FeatureClickAndZoomEventHandlerOptions) {
    super({ setCursor, tooltip });

    this.layerClassName = layerClassName;
  }

  protected addEventListeners(): void {
    this.createPointerMoveHandler();
    this.createClickHandler();
  }

  protected layerFilter(layer: Layer): boolean {
    return layer.getClassName() === this.layerClassName;
  }

  /**
   * When the mouse hovers over a feature we'll
   * set the cursor to a pointer (hand).
   */
  private createPointerMoveHandler(): void {
    if (this.map) {
      this.subscriptions.add(
        this.cursorFeature$.subscribe(({ feature, pixel }) => {
          if (feature) {
            this.setCursor(Cursor.pointer);

            // Only display a tooltip for non-cluster features.
            if (!isCluster(feature)) {
              feature = feature.get('features')[0] as FeatureLike;

              const [x, y] = pixel;

              this.tooltip.setPosition({
                x,
                y,
              });

              this.tooltip.setVisible(true);

              this.tooltip.setSubtitle(feature.get('siteName') ?? feature.get('siteId') ?? 'Unnamed');
            }

            return;
          }

          this.setCursor(DEFAULT_MAP_CURSOR);
          this.tooltip.setVisible(false);
        })
      );
    }
  }

  /**
   * When a feature is clicked we'll zoom to its extent.
   *
   * If the feature is a cluster (multiple features),
   * we'll calculate the extent of each feature and
   * zoom to the overall extent.
   */
  private createClickHandler(): void {
    if (this.map) {
      this.events.add(
        this.map.on('singleclick', (event) => {
          const featureAtPixel = this.map?.forEachFeatureAtPixel(event.pixel, (feature) => feature, {
            layerFilter: (layer) => this.layerFilter(layer),
          });

          if (featureAtPixel instanceof Feature) {
            const extent = getFeatureExtent(featureAtPixel);

            if (extent) {
              this.map?.getView().fit(extent, {
                duration: 200,
                maxZoom: 18,
                padding: isCluster(featureAtPixel) ? [100, 100, 100, 100] : undefined,
              });
            }
          }
        })
      );
    }
  }
}
