import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { GLOBAL_ROUTING_ACTION_CREATORS } from './routing-state.actions';

@Injectable()
export class GlobalRoutingStateEffects {
  routeChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GLOBAL_ROUTING_ACTION_CREATORS.routeChange),
      exhaustMap(({ route }) =>
        from(this.router.navigateByUrl(route)).pipe(
          map(() => GLOBAL_ROUTING_ACTION_CREATORS.routeChangeSuccess({ route })),
          catchError((error) => of(GLOBAL_ROUTING_ACTION_CREATORS.routeChangeFailure({ error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private router: Router) {}
}
