import { Injectable } from '@angular/core';
import { BrowserStorageService } from '@grid-ui/common';
import { GLOBAL_ROUTING_ACTION_CREATORS } from '@grid-ui/global-routing';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AnnouncementsDialogComponent } from '../../feature/components/announcements-dialog/announcements-dialog.component';
import { AnnouncementHttpService } from '../api-service/announcements-http.service';
import {
  AnnouncementItem,
  AnnouncementsHttpResponse,
  ModuleAnnouncementItemDetails,
  ModuleAnnouncementsHttpResponse,
} from '../models/announcements-http-response.model';
import { GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS } from './global-module-announcement.actions';
import { AnnouncementType } from './global-module-announcement.reducer';
import { globalModuleAnnouncementSelectors } from './global-module-announcement.selectors';

const defaultModalOptions: NgbModalOptions = {
  centered: true,
  backdrop: 'static',
  keyboard: false,
  size: 'lg',
  scrollable: false,
  modalDialogClass: 'announcement-detail-modal',
};

@Injectable()
export class GlobalModuleAnnouncementEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly announcementHttpService: AnnouncementHttpService,
    private readonly browserStorageService: BrowserStorageService,
    private readonly ngBoostrapModalService: NgbModal,
    private readonly store: Store
  ) {}

  loadAnnouncements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS.load),
      switchMap(() =>
        combineLatest([
          this.announcementHttpService.getAnnouncements(),
          this.announcementHttpService.getModuleAnnouncements(),
          of(this.browserStorageService.getLocal<any[] | null>('dismissedItems')),
        ])
      ),
      map(
        ([globalAnnouncements, moduleAnnouncements, dismissedItems]: [
          AnnouncementsHttpResponse,
          ModuleAnnouncementsHttpResponse,
          number[] | null
        ]) =>
          GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS.loadSucceeded({
            payload: {
              globalAnnouncements: globalAnnouncements.results.map((announcement: AnnouncementItem) => ({
                type: AnnouncementType.global,
                id: announcement.id.toString(),
                title: announcement.title,
                text: announcement.text,
                isDismissed: true,
                module: {
                  key: 'global',
                  name: 'Global',
                },
              })),
              moduleAnnouncements: moduleAnnouncements.results.map((announcement: ModuleAnnouncementItemDetails) => ({
                type: AnnouncementType.module,
                id: announcement.id,
                title: announcement.title,
                text: announcement.text,
                isDismissed: true,
                module: announcement?.module,
              })),
              dismissedItems: dismissedItems ? dismissedItems.map((item) => item.toString()) : [],
            },
          })
      ),
      catchError((error) => of(GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS.loadFailed({ payload: { error: new Error(error) } })))
    )
  );

  loadAnnouncementsSucceeded$ = createEffect(
    () =>
      combineLatest([
        this.actions$.pipe(ofType(GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS.loadSucceeded)),
        this.actions$.pipe(ofType(GLOBAL_ROUTING_ACTION_CREATORS.routeChangeSuccess)),
      ]).pipe(
        withLatestFrom(this.store.pipe(select(globalModuleAnnouncementSelectors.selectUndissmissedAnnouncements))),
        tap(([, announcements]) => {
          const sessionDismissedAnnouncements: string[] | null = this.browserStorageService.getSession('dismissedSessionItems');
          if (sessionDismissedAnnouncements !== null && sessionDismissedAnnouncements.length > 0) {
            announcements = announcements.filter((ann) => !sessionDismissedAnnouncements.includes(ann.id));
          }

          if (announcements.length > 0) {
            this.openGlobalAnnouncementsModal();
          }
        })
      ),
    { dispatch: false }
  );

  private openGlobalAnnouncementsModal() {
    const modalRef = this.ngBoostrapModalService.open(AnnouncementsDialogComponent, defaultModalOptions);
    return modalRef.result;
  }
}
