<div class="dropdown" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div
    class="help-icon-button"
    [ngClass]="{
      home: isHome,
      active: isMenuOpen,
      inactive: !isMenuOpen
    }"
  >
    <div class="fas fa-question"></div>
  </div>
  <div class="dropdown-content" *ngIf="isMenuOpen">
    <div class="triangle-wrapper"><div class="triangle"></div></div>
    <p class="dropdown-item">
      <a (click)="onRouteSelected(['grid-updates'])"> GRiD Updates </a>
    </p>
    <p class="dropdown-item">
      <a
        href="https://api.maplecroft.com/v3/document-library/document/quick-start-guide/"
        target="_blank"
        rel="noopener noreferrer"
        data-hook="quick-start-link"
        >Quick Start Guide</a
      >
    </p>
    <p class="dropdown-item" *ngFor="let route of helpMenuRoutes">
      <a tabIndex="0" (click)="onRouteSelected(route.link)">{{ route.name }}</a>
    </p>
    <p class="dropdown-item" *mcPermission="PermissionSelector.API_DOCS.LINK">
      <a href="https://api.maplecroft.com/documentation/" target="_blank" rel="noopener noreferrer" data-hook="help-menu-api-link"
        >Country Risk API</a
      >
    </p>
    <p class="dropdown-item" *mcPermission="PermissionSelector.API_DOCS_INDUSTRY.LINK">
      <a [href]="industryApiDocLink" target="_blank" rel="noopener noreferrer">Industry Risk API</a>
    </p>
    <p class="dropdown-item">
      <a href="mailto:support@maplecroft.com" target="_top" class="email-support">Email Support</a>
    </p>
  </div>
</div>
