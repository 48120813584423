import { Injectable } from '@angular/core';
import {
  API_SERVICES_CONFIG,
  Commodity,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  NonPaginatedResourceConfig,
  PortalHttpClient,
} from '@grid-ui/common';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ApiCommodityRiskCommodityCollection } from '../models';

/**
 * Service for accessing Commodities
 */
@Injectable()
export class CommodityRiskCommoditiesService {
  private resourceConfig: NonPaginatedResourceConfig;

  constructor(private readonly http: PortalHttpClient) {
    this.resourceConfig = API_SERVICES_CONFIG.v3.commodityRisk._configuration;
  }

  public getCommodities(
    searchParam: string | null,
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<Commodity[]> {
    const commodities$: Observable<Commodity[]> = this.http.get<ApiCommodityRiskCommodityCollection>(this.resourceConfig, options).pipe(
      share(),
      map((commodityCollection) => commodityCollection.results)
    );

    if (searchParam) {
      return this.filterCommodities(commodities$, searchParam);
    } else {
      return commodities$;
    }
  }

  private filterCommodities(commodities$: Observable<Commodity[]>, searchParam: any): Observable<Commodity[]> {
    commodities$ = commodities$.pipe(
      map((commodities) => commodities.filter((commodity) => commodity.name.toLocaleLowerCase().includes(searchParam)))
    );
    return commodities$;
  }
}
