import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '../providers';

export const SM_BREAKPOINT = 576;
export const MD_BREAKPOINT = 768;
export const LG_BREAKPOINT = 992;

@Injectable({
  providedIn: 'root',
})
export class ClientDimensionsService {
  constructor(@Inject(WINDOW) private readonly window: Window | any, @Inject(DOCUMENT) private readonly document: Document | undefined) {}

  public getClientWidth(): number {
    return this.document instanceof Document
      ? this.document.documentElement.clientWidth
      : this.window instanceof Window
      ? this.window.innerWidth
      : 0;
  }
}
