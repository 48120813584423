import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { HttpErrorConfigItem, HttpErrorConfigItems, HttpErrorHandlerService } from '../../../api';
import { ActionSuccessModalComponent } from '../action-success-modal.component';
import { ActionSuccessModalModule } from '../action-success-modal.module';
import { ActionFailureResponse, ActionSuccessModalState } from '../models';

/**
 * A service to manage an Action Success Modal
 */
@Injectable({
  providedIn: ActionSuccessModalModule,
})
export class ActionSuccessModalService {
  private apiErrors: HttpErrorConfigItems;

  public constructor(
    private readonly httpErrorHandlerService: HttpErrorHandlerService,
    private readonly ngBootstrapModalService: NgbModal
  ) {
    this.apiErrors = this.httpErrorHandlerService.globalErrorConfig;
  }

  /**
   * Show an action success modal which opens in "progress" state and then,
   * depending on whether the specified observable successfully emits or throws
   * and error. shows the "success" state or "failure" state modal, respectively.
   *
   * If the modal ends in "success" state, closing it will resolve to the data
   * emitted by the observable.
   *
   * If the modal ends in "failure" state, closing it will resolve to an instance
   * of ActionFailureState, which may be beneficial to determine error-code specific
   * follow-up actions.
   *
   * @param observable An observable, whose first emission/error triggers a change
   * to the sucess/failure state.
   * @param progressState An object providing the input information for the "progress"
   * state of the modal.
   * @param successState An object providing the input information for the "success"
   * state of the modal.
   * @param failureState An object providing the input information for the "failure"
   * state of the modal.
   */
  public showActionModal<T>(
    observable: Observable<T>,
    progressState: ActionSuccessModalState,
    successState: ActionSuccessModalState | null,
    failureState: ActionSuccessModalState
  ): Promise<T | ActionFailureResponse> {
    const modalRef = this.ngBootstrapModalService.open(ActionSuccessModalComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'grid-ui-action-success-modal',
    });

    const actionSuccessModal: ActionSuccessModalComponent = modalRef.componentInstance;

    actionSuccessModal.title = progressState.title;
    actionSuccessModal.message = progressState.message;
    actionSuccessModal.buttonLabel = progressState.buttonLabel;
    actionSuccessModal.responseState = progressState.responseState;

    observable.pipe(first()).subscribe(
      (data) => {
        if (successState) {
          actionSuccessModal.title = successState.title;
          actionSuccessModal.message = successState.message;
          actionSuccessModal.buttonLabel = successState.buttonLabel;
          actionSuccessModal.responseState = successState.responseState;
          actionSuccessModal.closeReason = data;
        } else {
          actionSuccessModal.closeReason = data;
          actionSuccessModal.close();
        }
      },
      (err: HttpErrorResponse) => {
        const errorItem: HttpErrorConfigItem = this.apiErrors[err.status] ? this.apiErrors[err.status] : this.apiErrors['default'];
        const failureResponse = new ActionFailureResponse(err, `${err.status !== 0 ? `${err.status}: ` : ''}${errorItem.message}` || null);
        actionSuccessModal.title = failureState.title;
        actionSuccessModal.message = failureState.message;
        actionSuccessModal.messageDetail = failureResponse.messageDetail;
        actionSuccessModal.buttonLabel = failureState.buttonLabel;
        actionSuccessModal.responseState = failureState.responseState;
        actionSuccessModal.closeReason = failureResponse;
        actionSuccessModal.allowHeaderDismiss = true;
      }
    );

    return modalRef.result;
  }

  /**
   * Show an action failure modal.
   *
   * @param failureState An object providing the input information for the "failure"
   * state of the modal.
   * @param failureResponse A failure response, which is returned when the Failure modal
   * is closed. It also contains a detailed error message to display in the modal, below
   * the main message and close button.
   */
  public showFailureModal(failureState: ActionSuccessModalState, failureResponse: ActionFailureResponse): Promise<ActionFailureResponse> {
    const modalRef = this.ngBootstrapModalService.open(ActionSuccessModalComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
    });
    const actionSuccessModal: ActionSuccessModalComponent = modalRef.componentInstance;

    actionSuccessModal.title = failureState.title;
    actionSuccessModal.message = failureState.message;
    actionSuccessModal.messageDetail = failureResponse.messageDetail;
    actionSuccessModal.buttonLabel = failureState.buttonLabel;
    actionSuccessModal.responseState = failureState.responseState;
    actionSuccessModal.closeReason = failureResponse;
    actionSuccessModal.allowHeaderDismiss = true;

    return modalRef.result;
  }
}
