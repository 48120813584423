import { GridReadyEvent, FirstDataRenderedEvent, RowDataChangedEvent, NewColumnsLoadedEvent } from '@ag-grid-community/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GeoLocation } from '@grid-ui/common';
import { LocationsGridGroupByColumn } from '../../shared-ui-components/locations-grid';
import { GridFilterModel } from './grid-filter-model.interface';
import { GridSortModel } from './grid-sort-model.interface';

export enum GridActionTypes {
  GROUP_BY_COLUMNS_SET = '[Grid] Group By Columns Set',
  FILTER_MODEL_CHANGED = '[Grid] Filter Model Changed',
  FILTER_MODEL_MODIFIED = '[Grid] Filter Model Modified',
  FILTERED_ROW_TOTAL_CHANGED = '[Grid] Filtered Row Total Changed',
  FIRST_DATA_RENDERED = '[Grid] First Data Rendered',
  GRID_READY = '[Grid] Grid Ready',
  NEW_COLUMNS_LOADED = '[Grid] New Columns Loaded',
  RELOAD_APP = '[Grid] Reload App',
  PINNED_ROWS_ADDED = '[Grid] Pinned Rows Added',
  ROW_DATA_CHANGED = '[Grid] Rows Data Changed',
  ROW_LOADING_SUCCESS = '[Grid] Row Loading Success',
  ROW_LOADING_ERROR = '[Grid] Row Loading Error',
  SELECTION_CHANGED = '[Grid] Selection Changed',
  SELECT_ALL_CHANGED = '[Grid] Select All Changed',
  SORT_MODEL_CHANGED = '[Grid] Sort Model Changed',
  VISIBLE_COLUMNS_CHANGED = '[Grid] Visible Columns Changed',
  ROW_CLICKED = '[Grid] Row Clicked',
  EDIT_BUTTON_CLICKED = '[Grid] Edit Action Clicked',
  DOWNLOAD_BUTTON_CLICKED = '[Grid] Download Action Clicked',
  DELETE_CUSTOM_ATTRIBUTE = '[Grid] Delete Custom Attribute',
  RENAME_CUSTOM_ATTRIBUTE = '[Grid] Rename Custom Attribute',
}

export class GridGroupByColumnsSetAction {
  readonly type = GridActionTypes.GROUP_BY_COLUMNS_SET;
  constructor(public payload: LocationsGridGroupByColumn[]) {}
}

export class GridFilterModelChangedAction {
  readonly type = GridActionTypes.FILTER_MODEL_CHANGED;
  constructor(public payload: GridFilterModel) {}
}

export class GridFilterModelModifiedAction {
  readonly type = GridActionTypes.FILTER_MODEL_MODIFIED;
  constructor(public payload: GridFilterModel) {}
}

export interface GridFilteredRowTotalChangedActionPayload {
  filteredTotal: number | null;
  noMatches?: boolean;
}

export class GridFilteredRowTotalChangedAction {
  readonly type = GridActionTypes.FILTERED_ROW_TOTAL_CHANGED;
  constructor(public payload: GridFilteredRowTotalChangedActionPayload) {}
}

export class GridFirstDataRenderedAction {
  readonly type = GridActionTypes.FIRST_DATA_RENDERED;
  constructor(public payload: FirstDataRenderedEvent) {}
}

export class GridNewColumnsLoadedAction {
  readonly type = GridActionTypes.NEW_COLUMNS_LOADED;
  constructor(public payload: NewColumnsLoadedEvent) {}
}

export class GridReadyAction {
  readonly type = GridActionTypes.GRID_READY;
  constructor(public payload: GridReadyEvent) {}
}

export class GridReloadAppAction {
  readonly type = GridActionTypes.RELOAD_APP;
}

export class GridRowDataChangedAction {
  readonly type = GridActionTypes.ROW_DATA_CHANGED;
  constructor(public payload: RowDataChangedEvent) {}
}

export interface GridRowLoadingErrorPayload {
  error: HttpErrorResponse;
  initialPageError: boolean;
}

export class GridRowLoadingErrorAction {
  readonly type = GridActionTypes.ROW_LOADING_ERROR;
  constructor(public payload: GridRowLoadingErrorPayload) {}
}

export interface GridRowLoadingSuccessPayload {
  requestStartRow: number;
  requestEndRow: number;
}

export class GridRowLoadingSuccessAction {
  readonly type = GridActionTypes.ROW_LOADING_SUCCESS;
  constructor(public payload: GridRowLoadingSuccessPayload) {}
}

export class GridSelectionChangedAction<D> {
  readonly type = GridActionTypes.SELECTION_CHANGED;
  constructor(public payload: D[]) {}
}

export interface GridSelectAllChangedActionPayload {
  selectAll: boolean;
  filterQueryParamsString?: string;
  excluded?: GeoLocation[];
}

export class GridSelectAllChangedAction {
  readonly type = GridActionTypes.SELECT_ALL_CHANGED;
  constructor(public payload: GridSelectAllChangedActionPayload) {}
}

export class GridSortModelChangedAction {
  readonly type = GridActionTypes.SORT_MODEL_CHANGED;
  constructor(public payload: GridSortModel) {}
}

export class GridVisibleColumnsChangedAction {
  readonly type = GridActionTypes.VISIBLE_COLUMNS_CHANGED;
  constructor(public payload: string[]) {}
}

export interface GridRowClickedPayload<D> {
  item: D;
  rowIndex: number | null;
}

export class GridRowClickedAction<D> {
  readonly type = GridActionTypes.ROW_CLICKED;
  constructor(public payload: GridRowClickedPayload<D>) {}
}

export class GridEditButtonClickedAction<D> {
  readonly type = GridActionTypes.EDIT_BUTTON_CLICKED;
  constructor(public payload: GridRowClickedPayload<D>) {}
}

export class GridDownloadButtonClickedAction<D> {
  readonly type = GridActionTypes.DOWNLOAD_BUTTON_CLICKED;
  constructor(public payload: GridRowClickedPayload<D>) {}
}

export interface GridDeleteCustomAttributeActionPayload {
  key: string;
  label: string;
}

export class GridDeleteCustomAttributeAction {
  readonly type = GridActionTypes.DELETE_CUSTOM_ATTRIBUTE;
  constructor(public payload: GridDeleteCustomAttributeActionPayload) {}
}

export interface GridRenameCustomAttributeActionPayload {
  key: string;
  label: string;
}

export class GridRenameCustomAttributeAction {
  readonly type = GridActionTypes.RENAME_CUSTOM_ATTRIBUTE;
  constructor(public payload: GridRenameCustomAttributeActionPayload) {}
}

export interface GridPinnedRowsAddedActionPayload {
  rowsAdded: number;
}

export class GridPinnedRowsAddedAction {
  readonly type = GridActionTypes.PINNED_ROWS_ADDED;
  constructor(public payload: GridPinnedRowsAddedActionPayload) {}
}

export type GridActionsUnion<D = any> =
  | GridGroupByColumnsSetAction
  | GridFilterModelChangedAction
  | GridFilterModelModifiedAction
  | GridFilteredRowTotalChangedAction
  | GridFirstDataRenderedAction
  | GridNewColumnsLoadedAction
  | GridReadyAction
  | GridReloadAppAction
  | GridRowDataChangedAction
  | GridRowLoadingErrorAction
  | GridRowLoadingSuccessAction
  | GridSelectionChangedAction<D>
  | GridSelectAllChangedAction
  | GridSortModelChangedAction
  | GridVisibleColumnsChangedAction
  | GridRowClickedAction<D>
  | GridEditButtonClickedAction<D>
  | GridDownloadButtonClickedAction<D>
  | GridDeleteCustomAttributeAction
  | GridRenameCustomAttributeAction
  | GridPinnedRowsAddedAction;
