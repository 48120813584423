import { Component, ContentChildren, QueryList, AfterContentInit, OnDestroy } from '@angular/core';
import { AccordionPanelComponent } from '../accordion-panel';
import { SlideInOutAnimation } from '../slide-in-out';

/** Example how to use:
 <grid-ui-accordion>
      <grid-ui-accordion-panel title="First Group">this is the content</grid-ui-accordion-panel>
      <grid-ui-accordion-panel title="Second Group">
        <input type="text" class="form-control" />
        <button>Send</button>
      </grid-ui-accordion-panel>
    </grid-ui-accordion>
 */

@Component({
  selector: 'grid-ui-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [SlideInOutAnimation],
})
export class AccordionComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(AccordionPanelComponent)
  private accordionPanels!: QueryList<AccordionPanelComponent>;
  private subscriptions: any[] = [];

  public ngAfterContentInit(): void {
    this.addSubscriptions();

    this.accordionPanels.changes.subscribe((): void => {
      this.removeSubscriptions();
      this.addSubscriptions();
    });
  }

  public ngOnDestroy(): void {
    this.removeSubscriptions();
  }

  private addSubscriptions(): void {
    this.accordionPanels.forEach((a) => {
      const subscription = a.toggle.subscribe((): void => {
        this.togglePanel(a);
      });
      this.subscriptions.push(subscription);
    });
  }

  private removeSubscriptions(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  private togglePanel(panel: AccordionPanelComponent): void {
    if (!panel.isDisabled) {
      panel.isActive = !panel.isActive;
      panel.animationState = panel.isActive ? 'in' : 'out';
    }
  }
}
