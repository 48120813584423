<div
  role="presentation"
  class="ag-cell-label-container"
  [class.ag-header-cell-sorted-asc]="sort === 'asc'"
  [class.ag-header-cell-sorted-desc]="sort === 'desc'"
  (click)="changeSort()"
  data-hook="select-header-container"
>
  <span
    #menuButton
    ref="eMenu"
    class="ag-header-icon ag-header-cell-menu-button"
    [class.ag-hidden]="!enableMenu"
    [class.ag-header-menu-always-show]="alwaysShowMenu"
    aria-hidden="true"
    (click)="openMenu($event)"
    data-hook="select-header-menu"
  >
    <span class="ag-icon ag-icon-menu" unselectable="on"></span>
  </span>

  <div ref="eLabel" role="presentation" class="ag-header-cell-label" unselectable="on">
    <div class="ag-selection-checkbox" [formGroup]="selectAllForm">
      <div class="ag-labeled ag-label-align-right ag-checkbox ag-input-field">
        <label class="ag-input-field-label ag-label ag-hidden ag-checkbox-label" title="Select All" for="grid-header-select-all"> </label>
        <div
          class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
          [class.ag-hidden]="hideSelectAll"
          [class.ag-checked]="selectAllControl.value"
          (click)="captureClick($event)"
          data-hook="select-header-checkbox-wrapper"
        >
          <input
            id="grid-header-select-all"
            type="checkbox"
            class="ag-input-field-input ag-checkbox-input"
            formControlName="selectAll"
            aria-label="select all"
            data-hook="select-header-checkbox"
          />
        </div>
      </div>
    </div>

    <span ref="eText" class="ag-header-cell-text" role="columnheader" unselectable="on" data-hook="select-header-label">
      {{ label }}
    </span>

    <span
      ref="eFilter"
      class="ag-header-icon ag-header-label-icon ag-filter-icon"
      [class.ag-hidden]="!columnFilterActive"
      aria-hidden="true"
      data-hook="select-header-filter"
    >
      <span class="ag-icon ag-icon-filter" unselectable="on"></span>
    </span>

    <!-- TODO: Consider adding in sort at DOM element for multi sort -->
    <span
      ref="eSortAsc"
      class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon"
      [class.ag-hidden]="!enableSorting || sort !== 'asc'"
      aria-hidden="true"
      data-hook="select-header-sort-asc"
    >
      <grid-ui-icon class="sorted-icon" name="sort-alphabetical-ascending"></grid-ui-icon>
    </span>
    <span
      ref="eSortDesc"
      class="ag-header-icon ag-header-label-icon ag-sort-descending-icon"
      [class.ag-hidden]="!enableSorting || sort !== 'desc'"
      aria-hidden="true"
      data-hook="select-header-sort-desc"
    >
      <grid-ui-icon class="sorted-icon" name="sort-alphabetical-descending"></grid-ui-icon>
    </span>
    <span
      ref="eSortNone"
      class="ag-header-icon ag-header-label-icon ag-sort-none-icon"
      [class.ag-hidden]="alwaysHideNoSort || (enableSorting && sort !== null)"
      aria-hidden="true"
      data-hook="select-header-sort-none"
    >
      <grid-ui-icon class="unsorted-icon" name="sort-alphabetical-unsorted"></grid-ui-icon>
    </span>
  </div>
</div>
