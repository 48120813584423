import { LoadingRequestStatus } from '@grid-ui/common';
import { createReducer, on } from '@ngrx/store';
import { GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS } from './global-module-announcement.actions';

export const GLOBAL_MODULE_ANNOUNCEMENT_FEATURE_KEY = 'GLOBAL_MODULE_ANNOUNCEMENT_FEATURE';

export enum AnnouncementType {
  global = 'GLOBAL',
  module = 'MODULE',
}

export interface AnnouncementVM {
  id: string;
  title: string;
  text: string;
  isDismissed: boolean;
  type: AnnouncementType;
  module: {
    key: string;
    name: string;
  };
}

export interface GlobalModuleAnnouncementState {
  announcements: AnnouncementVM[];
  dismissedItems: string[] | null;
  loadAnnouncementsApiResponse: LoadingRequestStatus;
}

export const initialGlobalModuleAnnouncementState: GlobalModuleAnnouncementState = {
  announcements: [],
  dismissedItems: null,
  loadAnnouncementsApiResponse: LoadingRequestStatus.initial,
};

const globalModuleAnnouncementStateReducer = createReducer(
  initialGlobalModuleAnnouncementState,
  on(GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS.load, (state) => ({
    ...state,
    loadAnnouncementsApiResponse: LoadingRequestStatus.loading,
  })),

  on(GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS.loadSucceeded, (state, { payload }) => ({
    ...state,
    announcements: [...payload.globalAnnouncements, ...payload.moduleAnnouncements],
    dismissedItems: payload.dismissedItems,
    loadAnnouncementsApiResponse: LoadingRequestStatus.loaded,
  })),

  on(GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS.loadFailed, (state, { payload }) => ({
    ...state,
    loadAnnouncementsApiResponse: LoadingRequestStatus.error,
  })),

  on(GLOBAL_MODULE_ANNOUNCEMENT_ACTION_CREATORS.dismiss, (state, { payload }) => ({
    ...state,
    dismissedItems: payload,
  }))
);

export function globalModuleAnnouncementReducerFn(
  state: GlobalModuleAnnouncementState | undefined,
  action: any
): GlobalModuleAnnouncementState {
  return globalModuleAnnouncementStateReducer(state, action);
}
