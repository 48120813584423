<ng-container *ngIf="extractStatus$ | async as status">
  <div [ngSwitch]="status" [className]="'content d-flex ' + status | lowercase">
    <div *ngSwitchCase="PollStatusEnum.Completed" class="d-flex align-items-center">
      <grid-ui-icon class="check-icon" name="tick-circle"> </grid-ui-icon>
      <span
        >Your extract has been saved to Extracts in your
        <a class="action-text download-link" [routerLink]="['/document-library/extracts']" (click)="onClose()">Document library</a> and is
        ready to download.
      </span>
      <a class="action-text download-link pl-1" (click)="downloadExtract()">Download now</a>
    </div>

    <ng-container *ngSwitchCase="PollStatusEnum.Active" class="d-flex align-items-center">
      <ng-container *ngTemplateOutlet="ongoingExtract"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="PollStatusEnum.NotStarted" class="d-flex align-items-center">
      <ng-container *ngTemplateOutlet="ongoingExtract"></ng-container>
    </ng-container>

    <div *ngSwitchCase="PollStatusEnum.TimedOut" class="d-flex align-items-center justify-content-between">
      <span>
        <grid-ui-icon class="alert-icon" name="exclamation-circle"> </grid-ui-icon>
        Sorry, your download has timed out. Please try again. If the problem persists please
        <a class="action-text" href="mailto: support@maplecroft.com <support@maplecroft.com>">contact us</a>.
      </span>
      <a class="action-text close-btn" (click)="onClose()" rel="noopener noreferrer">Close this message</a>
    </div>

    <div *ngSwitchCase="PollStatusEnum.FailedScoringInProgress" class="d-flex align-items-center justify-content-between">
      <span data-hook="extract-notification-failed-scoring-in-progress">
        <grid-ui-icon class="alert-icon" name="exclamation-circle"> </grid-ui-icon>
        This View is still being scored, please try again later or try extracting a different View
      </span>
      <a class="action-text close-btn" (click)="onClose()" rel="noopener noreferrer">Close this message</a>
    </div>

    <ng-container *ngSwitchCase="PollStatusEnum.Failed">
      <ng-container *ngTemplateOutlet="genericFail"> </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="PollStatusEnum.FailedDuplicate">
      <ng-container *ngTemplateOutlet="genericFail"> </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="PollStatusEnum.ParameterRequired">
      <ng-container *ngTemplateOutlet="genericFail"> </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #ongoingExtract>
  <div class="d-flex align-items-center">
    <grid-ui-loading [message]="''" [messagePosition]="'right'" [color]="'secondary'"></grid-ui-loading>
    <span class="generating">Your download is being generated. <a class="action-text" (click)="cancel()">Cancel download</a></span>
  </div>
</ng-template>

<ng-template #genericFail>
  <div class="d-flex align-items-center justify-content-between">
    <span>
      <grid-ui-icon class="alert-icon" name="exclamation-circle"> </grid-ui-icon>
      Sorry, your download has failed. Please try again. If the problem persists please
      <a class="action-text" href="mailto: support@maplecroft.com <support@maplecroft.com>">contact us</a>.
    </span>
    <a class="action-text close-btn" (click)="onClose()" rel="noopener noreferrer">Close this message</a>
  </div>
</ng-template>
