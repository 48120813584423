import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { PollStatusEnum } from '../../../../../shared-models/polling';
import { WindowUtilitiesService } from '../../../../../shared-utilities/window-services';
import { CountryRiskDataTableExtractNotificationService } from '../../../services';
import { ExtractDownloadedFromNotificationBar } from '../../../store/actions/notifications';
import * as fromNotificationBar from '../../../store/reducers';

@Component({
  selector: 'mc-data-table-extract-notification',
  templateUrl: './data-table-extract-notification.component.html',
  styleUrls: ['./data-table-extract-notification.component.scss'],
})
export class DataTableExtractNotificationComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<void>();

  public extractStatus$: Observable<PollStatusEnum> | null = null;
  public extractDownloadUrl: string;
  public PollStatusEnum = PollStatusEnum;
  private downloadExtractUrlSubscription: Subscription;

  constructor(
    private windowUtilitiesService: WindowUtilitiesService,
    private readonly extractNotificationService: CountryRiskDataTableExtractNotificationService,
    private readonly store: Store<fromNotificationBar.State>
  ) {}

  ngOnInit(): void {
    this.extractStatus$ = this.extractNotificationService.extractStatus();

    this.downloadExtractUrlSubscription = this.extractNotificationService
      .getDownloadExtractUrl()
      .subscribe((extractDownloadUrl: string) => {
        this.extractDownloadUrl = extractDownloadUrl;
      });
  }

  ngOnDestroy(): void {
    if (this.downloadExtractUrlSubscription) {
      this.downloadExtractUrlSubscription.unsubscribe();
    }
  }

  public cancel(): void {
    this.extractNotificationService.cancel();
  }

  public downloadExtract(): void {
    this.extractNotificationService.downloadExtract();
    this.store.dispatch(new ExtractDownloadedFromNotificationBar(this.extractDownloadUrl));
    this.windowUtilitiesService.downloadAsset(this.extractDownloadUrl);
    this.close.emit();
  }

  public onClose(): void {
    this.extractNotificationService.closeNotification();
  }
}
