import { CountryRiskView, RiskView } from '@grid-ui/common';
import { CommodityRiskView } from '../../shared-models';
import { ApiRiskConfiguration, ApiCountryRiskConfiguration, ApiCommodityRiskConfiguration } from '../models';

export function mapRiskViewFromApiToApp(apiView: ApiCommodityRiskConfiguration): CommodityRiskView;
export function mapRiskViewFromApiToApp(apiView: ApiCountryRiskConfiguration): CountryRiskView;
export function mapRiskViewFromApiToApp(apiView: ApiRiskConfiguration): RiskView {
  /* Turns an API representation of a view in to our CountryRiskView */
  const view: CountryRiskView = Object.assign(
    {},
    { ...apiView },
    {
      author: apiView.creator.first_name + (apiView.creator.last_name.length > 0 ? ` ${apiView.creator.last_name}` : ''),
    },
    {
      indices: {
        ...apiView.indices,
        id: `${apiView.indices.id}`,
      },
    },
    {
      locations: {
        ...apiView.locations,
        id: `${apiView.locations.id}`,
      },
    }
  );
  return view;
}
