<div class="center grid-ui-error-container" *ngIf="(params.shouldShowErrorMsg$ | async) && (params.error$ | async); let error">
  <grid-ui-error
    [errorCode]="error?.errorCode"
    [errorMessage]="error?.errorMessage"
    [errorPrimaryAction]="error?.errorPrimaryAction"
    [errorSecondaryAction]="error?.errorSecondaryAction"
    [showContact]="error?.showContact"
    [contactEmail]="error?.contactEmail"
    (primaryActionClick)="params.loadData()"
    (secondaryActionClick)="reloadApp()"
  >
  </grid-ui-error>
</div>

<div class="justify-content-center align-items-center loading-container" *ngIf="params.shouldShowLoadingMsg$ | async">
  <grid-ui-loading></grid-ui-loading>
</div>

<div *ngIf="shouldShowNoRowsMsg$ | async" [ngSwitch]="params.module">
  <div class="no-rows-container" *ngSwitchCase="modules.alerts">
    <div class="icon-wrapper d-flex">
      <svg>
        <use xlink:href="#add-announcement" />
      </svg>
    </div>
  </div>
  <h4 class="mt-4">No alerts created!</h4>
  <button class="btn mc-btn-primary mt-4" [routerLink]="['/country-risk/alerts/create']">
    <i class="fa fa-plus fa-1x mt-1 mr-2"></i>
    <span>Create New Alert</span>
  </button>
</div>
