<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title">Download data</span>
    <grid-ui-icon
      type="button"
      class="close-icon"
      aria-label="Cancel"
      data-hook="extract-confirm-cancel-close-button"
      (click)="cancel()"
      ngbTooltip="Close window"
      name="cross"
    >
    </grid-ui-icon>
  </div>
  <div class="modal-body">
    <div *ngIf="!loading">
      <h2 class="confirm-cancel-title">Cancel your extract?</h2>
      <h3 class="confirm-cancel-subtitle h-sup" *ngIf="options && options.module">
        Your extract for {{ options.module }} will be deleted.
      </h3>
      <h3 class="confirm-cancel-subtitle h-sup" *ngIf="!options || !options.module">Your extract will be deleted.</h3>
      <div class="confirm-cancel-content">
        <div class="alert-circle-icon-container">
          <grid-ui-icon class="alert-circle-icon" name="exclamation-circle"></grid-ui-icon>
        </div>
        <h3 class="lh-28 center">Are you sure you wish to cancel this extract?</h3>
        <div class="confirm-cancel-buttons">
          <button class="btn mc-btn-secondary mr-2" (click)="continue()" data-hook="continue-button">No, continue extract</button>
          <button class="btn mc-btn-primary" (click)="cancel()" data-hook="cancel-button">Yes, cancel extract</button>
        </div>
      </div>
    </div>
    <div class="loading-container" *ngIf="loading">
      <grid-ui-loading message="Cancelling your extract, please wait..."></grid-ui-loading>
    </div>
  </div>
</div>
