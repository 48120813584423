import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { Country, CountryRiskView, GeoLocation } from '@grid-ui/common';
import { CountryRiskViewsService } from '../../country-risk-views';
import { CountryRiskIndicesService } from '../../country-risk-indices';
import { CountriesService } from '../../countries';
import { CountryRiskIndex, CountryRiskInformationListingItemDatatype } from '../../../shared-models';

@Injectable({
  providedIn: 'root',
})
export class CountryRiskRecentViewsService {
  private readonly compareLastViewedDesc = (a: CountryRiskInformationListingItemDatatype, b: CountryRiskInformationListingItemDatatype) => {
    if (a.last_viewed === null && b.last_viewed === null) {
      return 0;
    }
    if (a.last_viewed === null || (b.last_viewed !== null && a.last_viewed < b.last_viewed)) {
      return 1;
    }
    if (b.last_viewed === null || a.last_viewed > b.last_viewed) {
      return -1;
    }
    return 0;
  };

  constructor(
    private readonly countryRiskViewsService: CountryRiskViewsService,
    private readonly countryRiskIndicesService: CountryRiskIndicesService,
    private readonly countriesService: CountriesService
  ) {}

  public getRecentViews(): Observable<Array<CountryRiskView | Country | GeoLocation | CountryRiskIndex>> {
    const queryParams = {
      sort: '-last_viewed',
      page_size: 5,
      page: 1,
    };
    return forkJoin([
      this.countryRiskViewsService.getMyRiskViews(null, { forceAPICall: true }, queryParams),
      this.countryRiskViewsService.getVeriskMaplecroftRiskViews({ forceAPICall: true }, queryParams),
      this.countriesService.getEnhancedCountriesCollection(queryParams),
      this.countryRiskIndicesService.getRiskIndices(queryParams),
    ]).pipe(
      map(([myViewsResponse, maplecroftViewsResponse, countriesResponse, riskIndicesResponse]) =>
        [...myViewsResponse, ...maplecroftViewsResponse, ...countriesResponse.results, ...riskIndicesResponse]
          .filter((v) => !!v.last_viewed)
          .sort(this.compareLastViewedDesc)
          .slice(0, 5)
      )
    );
  }
}
