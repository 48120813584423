import { FeatureLike } from 'ol/Feature';
import { getPointResolution } from 'ol/proj';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { StyleColorPalette } from '../color/style-color-palette';
import { LayerStyle } from './layer-style';

export interface LayerVisibility {
  readonly adminBoundaries: boolean;
  readonly streets: boolean;
  readonly greenery: boolean;
  readonly sand: boolean;
  readonly ocean: boolean;
  readonly waterBodies: boolean;
  readonly labels: boolean;
  readonly streetLabels: boolean;
  readonly buildings: boolean;
  readonly buildingLabels: boolean;
  readonly structures: boolean;
}

/**
 * A map style for our OSM vector tiles.
 */
export class OSMMapStyle extends LayerStyle {
  private largeCountryLabelsStyle: Style;
  private smallCountryLabelsStyle: Style;
  private nationalAdminBoundaryStyle: Style;
  private subnationalAdminBoundaryStyle: Style;
  private cityLabelsStyle: Style;
  private stateLabelsStyle: Style;
  private smallPlaceLabelsStyle: Style;
  private largePlaceLabelsStyle: Style;
  private countyPlaceLabelsStyle: Style;
  private streetLabelsStyle: Style;
  private largeStreetsStyle: Style;
  private smallStreetsStyle: Style;
  private buildingsStyle: Style;
  private buildingLabelsStyle: Style;
  private oceanStyle: Style;
  private waterBodiesStyle: Style;
  private greeneryStyle: Style;
  private sandStyle: Style;
  private structureStyle: Style;
  private structureLabelStyle: Style;

  constructor(colorPalette: StyleColorPalette, private layerVisibility: LayerVisibility) {
    super(colorPalette);

    this.largeCountryLabelsStyle = this.getLargeCountryLabelsStyle();
    this.smallCountryLabelsStyle = this.getSmallCountryLabelsStyle();
    this.nationalAdminBoundaryStyle = this.getNationalAdminBoundaryStyle();
    this.subnationalAdminBoundaryStyle = this.getSubnationalAdminBoundaryStyle();
    this.cityLabelsStyle = this.getCityLabelsStyle();
    this.stateLabelsStyle = this.getStateLabelsStyle();
    this.smallPlaceLabelsStyle = this.getSmallPlaceLabelsStyle();
    this.largePlaceLabelsStyle = this.getLargePlaceLabelsStyle();
    this.countyPlaceLabelsStyle = this.getCountyPlaceLabelsStyle();
    this.streetLabelsStyle = this.getStreetLabelsStyle();
    this.largeStreetsStyle = this.getLargeStreetsStyle();
    this.smallStreetsStyle = this.getSmallStreetsStyle();
    this.buildingsStyle = this.getBuildingsStyle();
    this.buildingLabelsStyle = this.getBuildingLabelsStyle();
    this.oceanStyle = this.getOceanStyle();
    this.waterBodiesStyle = this.getWaterBodiesStyle();
    this.greeneryStyle = this.getGreeneryStyle();
    this.sandStyle = this.getSandStyle();
    this.structureStyle = this.getStructureStyle();
    this.structureLabelStyle = this.getStructureLabelStyle();
  }

  /**
   * Rebuild all styles.
   *
   * This is a mirror of method calls within the constructor
   * and should be refactored to avoid the code duplication.
   */
  protected rebuildStyleCache(): void {
    this.largeCountryLabelsStyle = this.getLargeCountryLabelsStyle();
    this.smallCountryLabelsStyle = this.getSmallCountryLabelsStyle();
    this.nationalAdminBoundaryStyle = this.getNationalAdminBoundaryStyle();
    this.subnationalAdminBoundaryStyle = this.getSubnationalAdminBoundaryStyle();
    this.cityLabelsStyle = this.getCityLabelsStyle();
    this.stateLabelsStyle = this.getStateLabelsStyle();
    this.smallPlaceLabelsStyle = this.getSmallPlaceLabelsStyle();
    this.largePlaceLabelsStyle = this.getLargePlaceLabelsStyle();
    this.countyPlaceLabelsStyle = this.getCountyPlaceLabelsStyle();
    this.streetLabelsStyle = this.getStreetLabelsStyle();
    this.largeStreetsStyle = this.getLargeStreetsStyle();
    this.smallStreetsStyle = this.getSmallStreetsStyle();
    this.buildingsStyle = this.getBuildingsStyle();
    this.buildingLabelsStyle = this.getBuildingLabelsStyle();
    this.oceanStyle = this.getOceanStyle();
    this.waterBodiesStyle = this.getWaterBodiesStyle();
    this.greeneryStyle = this.getGreeneryStyle();
    this.sandStyle = this.getSandStyle();
    this.structureStyle = this.getStructureStyle();
    this.structureLabelStyle = this.getStructureLabelStyle();
  }

  private getTextFill(): Fill {
    return new Fill({
      color: this.colorPalette.textFill,
    });
  }

  private getTextStroke(): Stroke {
    return new Stroke({
      color: this.colorPalette.textStroke,
      width: 2.75,
    });
  }

  private getLargeCountryLabelsStyle(): Style {
    return new Style({
      zIndex: 10,
      text: new Text({
        font: '700 12px/1.2 Roboto, sans-serif',
        placement: 'point',
        fill: this.getTextFill(),
        stroke: this.getTextStroke(),
      }),
    });
  }

  private getSmallCountryLabelsStyle(): Style {
    return new Style({
      zIndex: 9,
      text: new Text({
        font: '700 11px/1.2 Roboto, sans-serif',
        placement: 'point',
        fill: this.getTextFill(),
        stroke: this.getTextStroke(),
      }),
    });
  }

  private getNationalAdminBoundaryStyle(): Style {
    return new Style({
      zIndex: 10,
      stroke: new Stroke({
        color: this.colorPalette.nationalAdminBoundaryStroke,
        width: 1,
      }),
    });
  }

  private getSubnationalAdminBoundaryStyle(): Style {
    return new Style({
      zIndex: 9,
      stroke: new Stroke({
        color: this.colorPalette.subnationalAdminBoundaryStroke,
        width: 1,
        lineDash: [3.2],
        lineCap: 'butt',
      }),
    });
  }

  private getCityLabelsStyle(): Style {
    return new Style({
      zIndex: 4,
      image: this.getSmallPlaceCircleStyle(),
      text: new Text({
        font: '400 11.5px Roboto, sans-serif',
        placement: 'point',
        offsetY: 10,
        fill: this.getTextFill(),
        stroke: this.getTextStroke(),
      }),
    });
  }

  private getStateLabelsStyle(): Style {
    return new Style({
      zIndex: 8,
      text: new Text({
        font: '700 9.5px Roboto, sans-serif',
        placement: 'point',
        fill: this.getTextFill(),
        stroke: this.getTextStroke(),
      }),
    });
  }

  private getSmallPlaceLabelsStyle(): Style {
    return new Style({
      zIndex: 8,
      text: new Text({
        font: '700 9.5px Roboto, sans-serif',
        placement: 'point',
        padding: [10, 10, 10, 10],
        fill: this.getTextFill(),
        stroke: this.getTextStroke(),
      }),
    });
  }

  private getLargePlaceLabelsStyle(): Style {
    return new Style({
      zIndex: 7,
      image: this.getLargePlaceCircleStyle(),
      text: new Text({
        font: '400 12px Roboto, sans-serif',
        placement: 'point',
        fill: this.getTextFill(),
        offsetY: 10,
        stroke: this.getTextStroke(),
      }),
    });
  }

  private getCountyPlaceLabelsStyle(): Style {
    return new Style({
      zIndex: 8,
      text: new Text({
        font: '700 10px Roboto, sans-serif',
        placement: 'point',
        fill: this.getTextFill(),
        stroke: this.getTextStroke(),
      }),
    });
  }

  private getStreetLabelsStyle(): Style {
    return new Style({
      zIndex: 3,
      text: new Text({
        font: '700 11px Roboto, sans-serif',
        placement: 'line',
        repeat: 600,
        fill: this.getTextFill(),
        stroke: this.getTextStroke(),
      }),
    });
  }

  private getLargeStreetsStyle(): Style {
    return new Style({
      zIndex: 3,
      stroke: new Stroke({
        color: this.colorPalette.largeStreetStroke,
      }),
    });
  }

  private getSmallStreetsStyle(): Style {
    return new Style({
      zIndex: 2,
      stroke: new Stroke({
        color: this.colorPalette.smallStreetStroke,
      }),
    });
  }

  private getBuildingsStyle(): Style {
    return new Style({
      zIndex: 3,
      fill: new Fill({
        color: this.colorPalette.buildingFill,
      }),
    });
  }

  private getBuildingLabelsStyle(): Style {
    return new Style({
      zIndex: 4,
      text: new Text({
        font: '400 10px Roboto, sans-serif',
        placement: 'point',
        fill: this.getTextFill(),
        stroke: this.getTextStroke(),
      }),
    });
  }

  private getOceanStyle(): Style {
    return new Style({
      zIndex: 2,
      fill: new Fill({
        color: this.colorPalette.oceanFill,
      }),
      stroke: new Stroke({
        color: this.colorPalette.oceanFill,
      }),
    });
  }

  private getWaterBodiesStyle(): Style {
    return new Style({
      zIndex: 2,
      fill: new Fill({
        color: this.colorPalette.waterBodiesFill,
      }),
      stroke: new Stroke({
        color: this.colorPalette.waterBodiesFill,
      }),
    });
  }

  private getGreeneryStyle(): Style {
    return new Style({
      zIndex: 1,
      fill: new Fill({
        color: this.colorPalette.greeneryFill,
      }),
    });
  }

  private getSandStyle(): Style {
    return new Style({
      zIndex: 1,
      fill: new Fill({
        color: this.colorPalette.sandFill,
      }),
    });
  }

  private getStructureStyle(): Style {
    return new Style({
      zIndex: 2,
      fill: new Fill({
        color: this.colorPalette.structureFill,
      }),
    });
  }

  private getStructureLabelStyle(): Style {
    return new Style({
      zIndex: 2,
      text: new Text({
        font: '700 10px Roboto, sans-serif',
        placement: 'point',
        fill: this.getTextFill(),
        stroke: new Stroke({
          color: this.colorPalette.textStroke,
          width: 1.5,
        }),
      }),
    });
  }

  private getSmallPlaceCircleStyle(): CircleStyle {
    return new CircleStyle({
      radius: 2.5,
      fill: new Fill({
        color: this.colorPalette.smallPlaceCircleFill,
      }),
      stroke: new Stroke({
        color: this.colorPalette.smallPlaceCircleStroke,
        width: 0.5,
      }),
    });
  }

  private getLargePlaceCircleStyle(): CircleStyle {
    return new CircleStyle({
      radius: 2.5,
      fill: new Fill({
        color: this.colorPalette.largePlaceCircleFill,
      }),
      stroke: new Stroke({
        color: this.colorPalette.largePlaceCircleStroke,
        width: 0.5,
      }),
    });
  }

  /**
   * Function get the feature name. We'll first try to get
   * `name_en` which is the English representation, followed
   * by `name`.
   *
   * These properties are set within the vector tiles. Other
   * languages can be added if desired.
   */
  private getFeatureName(feature: FeatureLike): string {
    return feature.get('name_en') ?? feature.get('name') ?? '';
  }

  /**
   * Function get the feature ref or name. We'll first try to get
   * `ref` followed by `name_en` and lastly `name`.
   *
   * This is generally used for street labels where we want to
   * prioritise the commonly known street reference, for example
   * M5 for Motorway 5.
   */
  private getFeatureRefOrName(feature: FeatureLike): string {
    return feature.get('ref') ?? feature.get('name_en') ?? feature.get('name') ?? '';
  }

  /**
   * Wrap text with a fixed width. Used for features such
   * as country labels where wrapped text makes visual sense.
   */
  private wrapText(text: string): string {
    return text.replace(/(?![^\n]{1,12}$)([^\n]{1,18})\s/g, '$1\n');
  }

  /**
   * Set layer visibility.
   * @param layerVisibility Layers to be shown.
   */
  public setLayerVisibility(layerVisibility: Partial<LayerVisibility>): void {
    this.layerVisibility = {
      ...this.layerVisibility,
      ...layerVisibility,
    };
  }

  /**
   * Get layer visibility.
   */
  public getLayerVisibility(): LayerVisibility {
    return this.layerVisibility;
  }

  /**
   * The Style function which OpenLayers will need to call.
   *
   * @example
   *
   * const mapStyle = new OSMMapStyle(this.props.colorPalette);
   *
   * new VectorTile({
   *   style: (f: FeatureLike, r: number) => mapStyle.getStyleFunction(f, r)
   * });
   */
  public getStyleFunction(feature: FeatureLike, resolution: number): Style | void {
    const layer = feature.get('layer');

    switch (layer) {
      case 'admin_boundaries':
        if (this.layerVisibility.adminBoundaries) {
          if (feature.get('admin_level') === 0) {
            return this.nationalAdminBoundaryStyle;
          } else {
            return this.subnationalAdminBoundaryStyle;
          }
        } else {
          return;
        }
      case 'large_country_labels':
        if (this.layerVisibility.labels) {
          this.largeCountryLabelsStyle.getText()?.setText(this.wrapText(this.getFeatureName(feature)).toUpperCase());
          return this.largeCountryLabelsStyle;
        } else {
          return;
        }
      case 'small_country_labels':
        if (this.layerVisibility.labels) {
          this.smallCountryLabelsStyle.getText()?.setText(this.wrapText(this.getFeatureName(feature)).toUpperCase());
          return this.smallCountryLabelsStyle;
        } else {
          return;
        }
      case 'capital_labels':
        if (this.layerVisibility.labels) {
          this.largePlaceLabelsStyle.getText()?.setText(this.getFeatureName(feature));
          return this.largePlaceLabelsStyle;
        } else {
          return;
        }
      case 'state_capital_labels':
        if (this.layerVisibility.labels) {
          this.largePlaceLabelsStyle.getText()?.setText(this.getFeatureName(feature));
          return this.largePlaceLabelsStyle;
        } else {
          return;
        }
      case 'city_labels':
        if (this.layerVisibility.labels) {
          this.cityLabelsStyle.getText()?.setText(this.getFeatureName(feature));
          return this.cityLabelsStyle;
        } else {
          return;
        }
      case 'county_labels':
        if (this.layerVisibility.labels) {
          this.countyPlaceLabelsStyle.getText()?.setText(this.wrapText(this.getFeatureName(feature)).toUpperCase());
          return this.countyPlaceLabelsStyle;
        } else {
          return;
        }
      case 'town_labels':
        if (this.layerVisibility.labels) {
          this.largePlaceLabelsStyle.getText()?.setText(this.wrapText(this.getFeatureName(feature)));
          return this.largePlaceLabelsStyle;
        } else {
          return;
        }
      case 'small_place_labels':
        if (this.layerVisibility.labels) {
          this.smallPlaceLabelsStyle.getText()?.setText(this.wrapText(this.getFeatureName(feature)));
          return this.smallPlaceLabelsStyle;
        } else {
          return;
        }
      case 'hamlet_labels':
        if (this.layerVisibility.labels) {
          this.smallPlaceLabelsStyle.getText()?.setText(this.wrapText(this.getFeatureName(feature)).toUpperCase());
          return this.smallPlaceLabelsStyle;
        } else {
          return;
        }
      case 'suburb_labels':
        if (this.layerVisibility.labels) {
          this.smallPlaceLabelsStyle.getText()?.setText(this.wrapText(this.getFeatureName(feature)).toUpperCase());
          return this.smallPlaceLabelsStyle;
        } else {
          return;
        }
      case 'state_labels':
        if (this.layerVisibility.labels) {
          this.stateLabelsStyle.getText()?.setText(this.wrapText(this.getFeatureName(feature)).toUpperCase());
          return this.stateLabelsStyle;
        } else {
          return;
        }
      case 'small_street_labels':
        if (this.layerVisibility.streetLabels) {
          this.streetLabelsStyle.getText()?.setText(this.getFeatureName(feature));
          return this.streetLabelsStyle;
        } else {
          return;
        }
      case 'large_street_labels':
        if (this.layerVisibility.streetLabels) {
          this.streetLabelsStyle.getText()?.setText(this.getFeatureRefOrName(feature));
          return this.streetLabelsStyle;
        } else {
          return;
        }
      case 'large_streets':
        if (this.layerVisibility.streets) {
          /**
           * Setting the width of large streets in meters (14).
           * Fixes an issue where they appear super thick when
           * the map is zoomed out.
           *
           * Setting a minimum width of 0.7 due to Chrome not
           * rendering features when they have a tiny width.
           */
          const extent = feature.getGeometry()?.getExtent();
          if (extent) {
            /**
             * The constant '17' is the base width for streets.
             * e.g. when the map is zoomed out.  Because this
             * is a dynamic calculation based on the zoom level,
             * Math.max is used to ensure we don't fall below a
             * certain value.
             */
            const width = 17 / getPointResolution('EPSG:3857', resolution, extent);
            this.largeStreetsStyle.getStroke()?.setWidth(Math.max(width, 0.7));
          }
          return this.largeStreetsStyle;
        } else {
          return;
        }
      case 'small_streets':
        if (this.layerVisibility.streets) {
          /**
           * Setting the width of small streets in meters (6).
           * Fixes an issue where they appear super thick when
           * the map is zoomed out.
           */
          const extent = feature.getGeometry()?.getExtent();
          if (extent) {
            /**
             * The constant '9' is the base width for streets.
             * e.g. when the map is zoomed out.  Because this
             * is a dynamic calculation based on the zoom level,
             * Math.max is used to ensure we don't fall below a
             * certain value.
             */
            const width = 9 / getPointResolution('EPSG:3857', resolution, extent);
            this.smallStreetsStyle.getStroke()?.setWidth(Math.max(width, 1.5));
          }
          return this.smallStreetsStyle;
        } else {
          return;
        }
      case 'buildings':
        if (this.layerVisibility.buildings) {
          return this.buildingsStyle;
        } else {
          return;
        }
      case 'building_labels':
        if (this.layerVisibility.buildingLabels && resolution < 0.8) {
          this.buildingLabelsStyle.getText()?.setText(this.wrapText(this.getFeatureName(feature)));
          return this.buildingLabelsStyle;
        } else {
          return;
        }
      case 'ocean':
        if (this.layerVisibility.ocean) {
          return this.oceanStyle;
        } else {
          return;
        }
      case 'water_bodies':
        if (this.layerVisibility.waterBodies) {
          return this.waterBodiesStyle;
        } else {
          return;
        }
      case 'greenery':
        if (this.layerVisibility.greenery) {
          return this.greeneryStyle;
        } else {
          return;
        }
      case 'sand':
        if (this.layerVisibility.sand) {
          return this.sandStyle;
        } else {
          return;
        }
      case 'structure_labels':
        if (this.layerVisibility.labels) {
          this.structureLabelStyle.getText()?.setText(this.wrapText(this.getFeatureName(feature)));
          return this.structureLabelStyle;
        } else {
          return;
        }
      case 'structures':
        if (this.layerVisibility.structures) {
          return this.structureStyle;
        } else {
          return;
        }
    }
  }
}
