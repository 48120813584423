import { GridOptions, SideBarDef } from '@ag-grid-community/core';
import { TEXT_CUSTOM_ICONS } from '../util';

export const STANDARD_GRID_OPTIONS: Readonly<GridOptions> = Object.freeze({
  suppressMenuHide: true,
  suppressContextMenu: true,
  defaultColDef: {
    suppressMenu: false,
    menuTabs: ['filterMenuTab'],
    resizable: false,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['clear', 'apply'],
      filterOptions: ['contains'],
      defaultOption: 'contains',
      suppressAndOrCondition: true,
      closeOnApply: true,
    },
  },
  icons: {
    ...TEXT_CUSTOM_ICONS,
    sortUnSort: undefined,
  },
  sideBar: {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
    position: 'right',
  } as SideBarDef,
});
