import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import fontawesome from '@fortawesome/fontawesome';
import { faFile, faFilePdf, faFilePowerpoint } from '@fortawesome/fontawesome-free-regular';
import { faCheck, faTimes } from '@fortawesome/fontawesome-free-solid';
import { AccountSettingsModalResolvedValues } from '@grid-ui/account-personal-details';
import { AllCountriesService } from '@grid-ui/common';
import { environment } from '@grid-ui/environment';
import {
  WhoAmIUserPersonalInformationChange,
  getWhoAmI,
  selectWhoAmI,
  selectWhoAmIPersonalInformation,
  selectWhoAmIUserSavingStatus,
  whoAmIResetSaveUser,
  whoAmISaveUser,
} from '@grid-ui/whoami';
import { NgbConfig } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { GridAnalyticsEventService } from '../api-services';
import { AccountSettingsModalService, AppState, TopUserMenuActionTypes, TopUserMenuActionsUnion } from '../core';
import { PrivacySettingsActionTypes, PrivacySettingsActionsUnion, PrivacySettingsStorageService } from '../privacy-settings';
import { WindowUtilitiesService } from '../shared-utilities/window-services';

@Component({
  selector: 'mc-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, AfterViewInit, OnDestroy {
  public isSplashScreen = false;
  public loading: boolean;
  public notificationBarOpen = false;
  public isPreviewMode: boolean;
  public announcementBarActive = false;
  public globalAnnouncementBarStatus = new Subject<boolean>();

  private readonly subscriptions = new Subscription();

  private readonly defaultChangePasswordHref = '/accounts/password/change/';

  constructor(
    ngbConfig: NgbConfig,
    public readonly privacySettingsStorageService: PrivacySettingsStorageService,
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly accountSettingsModalService: AccountSettingsModalService,
    private readonly allCountriesService: AllCountriesService,
    private readonly windowUtilitiesService: WindowUtilitiesService,
    private readonly gridAnalyticsEventService: GridAnalyticsEventService
  ) {
    fontawesome.library.add(faCheck, faTimes, faFile, faFilePdf, faFilePowerpoint);
    if (!environment.production) {
      ngbConfig.animation = false;
    }
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.url === '/' || this.router.url === '/home')
      )
      .subscribe((v) => (this.isSplashScreen = v));
  }

  public ngOnInit(): void {
    this.store.dispatch(getWhoAmI());

    this.store
      .select(selectWhoAmI)
      .pipe(take(1))
      .subscribe((whoAmI) => {
        if (whoAmI) {
          this.isPreviewMode = whoAmI.is_preview_mode;

          this.subscriptions.add(
            this.gridAnalyticsEventService
              .sendSiteLoadedEvent(whoAmI)
              .pipe(take(1))
              .subscribe({ error: () => {} })
          );
        }
      });
  }

  public ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const url = event.url;

        // HACK: Do not show "app-level" loading component, when navigating
        // within Country Risk/Profile Details or Commodity Risk/Profile Details etc..
        // in favour of their more specific UI behaviour ("persistent" Table of Content)
        const excludedPaths: any[] = [
          // Country Profile Detail
          /^\/country-risk\/profiles\/[0-9]{1,}/,
          // Commodity Profile Detail
          /^\/commodity-risk\/profiles\/[0-9]{1,}/,
        ];

        let loadComponentStatus = true;
        excludedPaths.forEach((excludedPath) => {
          if (excludedPath.test(url) && excludedPath.test(this.router.url)) {
            loadComponentStatus = false;
          }
        });

        this.loading = loadComponentStatus;
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.loading = false;
      }
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public handleCookiesNotificationBannerAction(action: PrivacySettingsActionsUnion): void {
    switch (action.type) {
      case PrivacySettingsActionTypes.ACCEPT:
        this.privacySettingsStorageService.acceptCookies();
        break;
    }
  }

  public handleNotificationBarUpdate(isOpen: boolean): void {
    setTimeout(() => {
      this.notificationBarOpen = isOpen;
    }, 0);
  }

  public handleTopMenuAction(action: TopUserMenuActionsUnion): void {
    switch (action.type) {
      case TopUserMenuActionTypes.ACCOUNT_SETTINGS:
        this.changeAccountSettings();
        break;
      case TopUserMenuActionTypes.LOGOUT:
        this.windowUtilitiesService.setHref('/accounts/logout');
        break;
      case TopUserMenuActionTypes.SUBSCRIPTIONS:
        this.router.navigate(['subscriptions/details/country-risk']);
        break;
    }
  }

  public announcementBarDisplayed(active: boolean) {
    this.announcementBarActive = active;
    this.globalAnnouncementBarStatus.next(active);
  }

  private getSaveDispatcher(): (payload: WhoAmIUserPersonalInformationChange) => void {
    const store = this.store;
    return function (payload: WhoAmIUserPersonalInformationChange): void {
      store.dispatch(whoAmISaveUser({ payload }));
    };
  }

  private async changeAccountSettings(): Promise<void> {
    this.store
      .select(selectWhoAmIPersonalInformation)
      .pipe(take(1))
      .subscribe((userPersonalInformation) => {
        if (userPersonalInformation !== null) {
          this.store.dispatch(whoAmIResetSaveUser());
          this.accountSettingsModalService
            .showModal(
              userPersonalInformation,
              this.store.pipe(select(selectWhoAmIUserSavingStatus)),
              this.allCountriesService.getAllCountries(),
              this.getSaveDispatcher()
            )
            .then((result) => {
              this.store.dispatch(whoAmIResetSaveUser());
              if (result === AccountSettingsModalResolvedValues.CHANGE_PASSWORD) {
                const changePasswordUrl = environment.changePasswordUrl || this.defaultChangePasswordHref;
                this.windowUtilitiesService.setHref(changePasswordUrl);
              }
            })
            .catch(() => {
              /** Do Nothing */
            });
        }
      });
  }
}
