/**
 * Represents deprecated content types
 * @export
 * @class DeprecatedContentBlockTypeError
 */
export class DeprecatedContentBlockTypeError {
  constructor(
    public type: string, // The deprecated content type
    public url: string, // The API url containing the deprecated content
    public error: TypeError // Any error object
  ) {}
}
