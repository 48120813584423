import { AnalyseSidePanelTabType, AnalyseFlatTreeNode } from '../../shared-models';
import { AnalyseNodeSelection, AnalyseReferenceNodeSelection, AnalyseGroupNodeSelection } from './analyse-node-selection.type';

/**
 * Tree edit status event indicating that there is no current action
 * for the specified tree.
 */
export class AnalyseTreeEditNoAction {
  /** Tree edit status */
  public readonly status = 'noaction';
  /**
   * Create a new AnalyseTreeEditNoAction event for the specified tree.
   *
   * @param treeType The type of the tree.
   */
  constructor(public treeType: AnalyseSidePanelTabType) {}
}

/**
 * Tree edit status event indicating that there is an edit action in progress
 * for the specified tree
 */
export class AnalyseTreeEditInProgress {
  /** Tree edit status */
  public readonly status = 'progress';
  /**
   * Create a new AnalyseTreeEditInProgress event for the specified tree.
   *
   * @param treeType The type of the tree.
   */
  constructor(public treeType: AnalyseSidePanelTabType) {}
}

/**
 * Tree edit status event indicating that the last edit action
 * for the specified tree succeeded.
 */
export class AnalyseTreeEditSuccess {
  /** Tree edit status */
  public readonly status = 'success';
  public firstChildSelection: AnalyseGroupNodeSelection | AnalyseReferenceNodeSelection | null;
  /**
   * Create a new AnalyseTreeEditSuccess event for the specified tree.
   *
   * @param treeType The type of the tree.
   * @param firstChild The first child node of the tree root, or null for an empty tree.
   * @param resetNodeSelection An optional new node selection, if the
   * tree edit affected the current node selection (i.e. leaf node move, removal of the node or
   * addition of a new group node, which shall become the new selection). Set to null, if the
   * edit tree became empty through the removal of the last child node of the tree root.
   * @param preserveEditTarget Flag indicating, whether the edit target node should be preserved
   * event though the node selection is to be reset. _This flag is set to true only under exceptional
   * circumstances_. Specifically, it should only be set to true, if the tree root node is the
   * edit target and the first node was added. In these circumstances, we want to make the first child the
   * "formal" selection to allow data to load properly, but retain the edit target on the
   * tree root, in case the user wants to continue adding nodes under the root.
   */
  constructor(
    public treeType: AnalyseSidePanelTabType,
    firstChild: AnalyseFlatTreeNode | null,
    public resetNodeSelection?: AnalyseNodeSelection | null,
    public preserveEditTarget?: boolean
  ) {
    this.firstChildSelection =
      firstChild !== null
        ? firstChild.type === 'group'
          ? { type: 'group', nodeId: firstChild.id }
          : { type: 'leaf', nodeId: firstChild.id, parentGroupId: firstChild.parent }
        : null;
  }
}

/**
 * Tree edit status event indicating that the last edit action
 * for the specified tree failed.
 */
export class AnalyseTreeEditFailure {
  /** Tree edit status */
  public readonly status = 'failure';
  /**
   * Create a new AnalyseTreeEditFailure event for the specified tree.
   *
   * @param treeType The type of the tree.
   */
  constructor(public treeType: AnalyseSidePanelTabType) {}
}

/**
 * A tree edit status event
 */
export type AnalyseTreeEditStatus = AnalyseTreeEditNoAction | AnalyseTreeEditInProgress | AnalyseTreeEditSuccess | AnalyseTreeEditFailure;
