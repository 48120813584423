import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_SERVICES_CONFIG } from '../../../../api';
import { ErrorComponentData, ErrorComponentSize } from '../../../../shared-models';
import { HttpErrorConfigItem, HttpErrorConfigItems } from '../models';

/**
 * This is a utility service to support component-level error handling of client and server-side
 * errors
 *
 * @export
 * @class HttpErrorHandlerService
 */
@Injectable({ providedIn: 'root' })
export class HttpErrorHandlerService {
  public globalErrorConfig: HttpErrorConfigItems;

  public constructor() {
    this.globalErrorConfig = API_SERVICES_CONFIG.globalHttpErrorConfig;
  }

  /**
   * Map a HttpErrorResponse to a data object containing all
   * inputs needed for use of ErrorComponent.
   *
   * @param error Original HttpErrorResponse
   * @param primaryErrorAction Label of primary action button. Use null to prevent
   * this button from being displayed. Defaults to 'Try Again'.
   * @param secondaryErrorAction Label of secondary action button. Use null to prevent
   * this button from being displayed. Defaults to 'Reload App'.
   * @param displaySize Optional parameter determining the size of the error component
   * when displayed. Defaults to 'full'.
   */
  public mapHttpErrorResponseToUIComponent(
    error: HttpErrorResponse,
    primaryErrorAction: string | null = 'Try Again',
    secondaryErrorAction: string | null = 'Reload App',
    displaySize?: ErrorComponentSize
  ): ErrorComponentData {
    const errorComponentData: ErrorComponentData = {};

    const errorItem: HttpErrorConfigItem = this.globalErrorConfig[error.status]
      ? this.globalErrorConfig[error.status]
      : this.globalErrorConfig['default'];

    errorComponentData.errorMessage = errorItem.message;
    errorComponentData.showContact = errorItem.showContact;
    errorComponentData.contactEmail = errorItem.contactEmail;
    errorComponentData.errorPrimaryAction = primaryErrorAction;
    errorComponentData.errorSecondaryAction = secondaryErrorAction;
    if (error.status !== 0) {
      errorComponentData.errorCode = error.status;
    }
    if (displaySize) {
      errorComponentData.size = displaySize;
    }
    return errorComponentData;
  }
}
