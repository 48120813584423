import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  API_SERVICES_CONFIG,
  DEFAULT_HTTP_GET_CUSTOM_OPTIONS,
  HttpGETCustomOptions,
  NonPaginatedResourceConfig,
  PaginatedResourceConfig,
  PaginationService,
  PortalHttpClient,
} from '../../../api';
import {
  DataAlertDownloadBody,
  DataAlertDownloadRequest,
  DataAlertNotification,
  DataAlertNotificationPaginatedCollection,
  DataAlertNotificationQueryParams,
  DataAlertNotifications,
  DataAlertPaginatedCollection,
  DataAlertQueryParams,
  DataAlerts,
  DataAlertTrigger,
  DataAlertTriggerBody,
  DataAlertTriggerPaginatedCollection,
  DataAlertTriggerQueryParams,
  DataAlertTriggers,
} from '../../models';

export const DATA_ALERT_TRIGGERS_LIMIT = 10;

@Injectable()
export class DataAlertService {
  private triggerResourceConfig: NonPaginatedResourceConfig;
  private triggersResourceConfig: PaginatedResourceConfig;
  private duplicateTriggerResourceConfig: NonPaginatedResourceConfig;
  private notificationsResourceConfig: PaginatedResourceConfig;
  private notificationResourceConfig: NonPaginatedResourceConfig;
  private alertsResourceConfig: PaginatedResourceConfig;
  private downloadsConfig: PaginatedResourceConfig;
  private downloadsDetailConfig: NonPaginatedResourceConfig;

  public constructor(private readonly http: PortalHttpClient, private readonly paginationService: PaginationService) {
    this.triggersResourceConfig = API_SERVICES_CONFIG.feApi.dataAlerts.trigger._configuration;
    this.triggerResourceConfig = API_SERVICES_CONFIG.feApi.dataAlerts.trigger.detail._configuration;
    this.duplicateTriggerResourceConfig = API_SERVICES_CONFIG.feApi.dataAlerts.trigger.detail.duplicate._configuration;
    this.notificationsResourceConfig = API_SERVICES_CONFIG.feApi.dataAlerts.notification._configuration;
    this.notificationResourceConfig = API_SERVICES_CONFIG.feApi.dataAlerts.notification.detail._configuration;
    this.alertsResourceConfig = API_SERVICES_CONFIG.feApi.dataAlerts.alert._configuration;
    this.downloadsConfig = API_SERVICES_CONFIG.feApi.dataAlerts.downloadRequest._configuration;
    this.downloadsDetailConfig = API_SERVICES_CONFIG.feApi.dataAlerts.downloadRequest.detail._configuration;
  }

  public getTriggers(
    queryParams: DataAlertTriggerQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<DataAlertTriggerPaginatedCollection> {
    return this.http
      .getPaginated<DataAlertTriggers>(this.triggersResourceConfig, {
        ...options,
        queryParams,
      })
      .pipe(
        map((responseContext) => ({
          total: responseContext.response.total,
          results: responseContext.response.results,
          paginationContext: this.paginationService.getNewPaginationContext(responseContext.response.links, responseContext.queryParams),
        }))
      );
  }

  public getTrigger(triggerId: string): Observable<DataAlertTrigger> {
    return this.http.get<DataAlertTrigger>(this.triggerResourceConfig, { pathParams: { triggerId } });
  }

  public createTrigger(body: DataAlertTriggerBody): Observable<DataAlertTrigger> {
    return this.http.post<DataAlertTrigger>(this.triggersResourceConfig, { body });
  }

  public updateTrigger(triggerId: string, body: Partial<DataAlertTriggerBody>): Observable<DataAlertTrigger> {
    return this.http.patch<DataAlertTrigger>(this.triggerResourceConfig, { body, pathParams: { triggerId } });
  }

  public deleteTrigger(triggerId: string): Observable<null> {
    return this.http.delete(this.triggerResourceConfig, { pathParams: { triggerId } });
  }

  public duplicateTrigger(triggerId: string): Observable<null> {
    return this.http.post(this.duplicateTriggerResourceConfig, { body: null, pathParams: { triggerId } });
  }

  public getNotifications(
    queryParams: DataAlertNotificationQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<DataAlertNotificationPaginatedCollection> {
    return this.http
      .getPaginated<DataAlertNotifications>(this.notificationsResourceConfig, {
        ...options,
        queryParams,
      })
      .pipe(
        map((responseContext) => ({
          total: responseContext.response.total,
          results: responseContext.response.results,
          paginationContext: this.paginationService.getNewPaginationContext(responseContext.response.links, responseContext.queryParams),
        }))
      );
  }

  public getNotification(notificationId: string): Observable<DataAlertNotification> {
    return this.http.get<DataAlertNotification>(this.notificationResourceConfig, { pathParams: { notificationId } });
  }

  public getAlerts(
    queryParams: DataAlertQueryParams = {},
    options: HttpGETCustomOptions = DEFAULT_HTTP_GET_CUSTOM_OPTIONS
  ): Observable<DataAlertPaginatedCollection> {
    return this.http
      .getPaginated<DataAlerts>(this.alertsResourceConfig, {
        ...options,
        queryParams,
      })
      .pipe(
        map((responseContext) => ({
          total: responseContext.response.total,
          results: responseContext.response.results,
          paginationContext: this.paginationService.getNewPaginationContext(responseContext.response.links, responseContext.queryParams),
        }))
      );
  }

  public createDownload(body: DataAlertDownloadBody): Observable<DataAlertDownloadRequest> {
    return this.http.post(this.downloadsConfig, { body });
  }

  public getDownload(requestId: string): Observable<DataAlertDownloadRequest> {
    return this.http.get(this.downloadsDetailConfig, { pathParams: { requestId } });
  }
}
